const imageContext = require.context(
  '../Default',
  false,
  /\.(jpg|jpeg|png|webp)$/,
);
const categoryData = imageContext.keys().map(imageContext);

let db = [
  {
    name: 'Egg',
    nameKo: '알',
    code: '000',
    type: [],
    url: '',
  },
  {
    name: 'Bulbasaur',
    nameKo: '이상해씨',
    code: '001',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Ivysaur',
    nameKo: '이상해풀',
    code: '002',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Venusaur',
    nameKo: '이상해꽃',
    code: '003',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Venusaur-Mega',
    nameKo: '메가이상해꽃',
    code: '003-m',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Charmander',
    nameKo: '파이리',
    code: '004',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Charmeleon',
    nameKo: '리자드',
    code: '005',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Charizard',
    nameKo: '리자몽',
    code: '006',
    type: ['fire', 'flying'],
    url: '',
  },
  {
    name: 'Charizard-Mega-X',
    nameKo: '메가리자몽-X',
    code: '006-m1',
    type: ['fire', 'dragon'],
    url: '',
  },
  {
    name: 'Charizard-Mega-Y',
    nameKo: '메가리자몽-Y',
    code: '006-m2',
    type: ['fire', 'flying'],
    url: '',
  },
  {
    name: 'Squirtle',
    nameKo: '꼬부기',
    code: '007',
    type: ['water'],
    url: '',
  },
  {
    name: 'Wartortle',
    nameKo: '어니부기',
    code: '008',
    type: ['water'],
    url: '',
  },
  {
    name: 'Blastoise',
    nameKo: '거북왕',
    code: '009',
    type: ['water'],
    url: '',
  },
  {
    name: 'Blastoise-Mega',
    nameKo: '메가거북왕',
    code: '009-m',
    type: ['water'],
    url: '',
  },
  {
    name: 'Caterpie',
    nameKo: '캐터피',
    code: '0010',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Metapod',
    nameKo: '단데기',
    code: '0011',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Butterfree',
    nameKo: '버터풀',
    code: '0012',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Weedle',
    nameKo: '뿔충이',
    code: '0013',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Kakuna',
    nameKo: '딱충이',
    code: '0014',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Beedrill',
    nameKo: '독침붕',
    code: '0015',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Beedrill-Mega',
    nameKo: '메가독침붕',
    code: '0015-m',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Pidgey',
    nameKo: '구구',
    code: '0016',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Pidgeotto',
    nameKo: '피죤',
    code: '0017',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Pidgeot',
    nameKo: '피죤투',
    code: '0018',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Pidgeot-Mega',
    nameKo: '메가피죤투',
    code: '0018-m',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Rattata',
    nameKo: '꼬렛',
    code: '0019',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Rattata-Alola',
    nameKo: '알로라꼬렛',
    code: '0019-Alola',
    type: ['normal', 'dark'],
    url: '',
  },
  {
    name: 'Raticate',
    nameKo: '레트라',
    code: '0020',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Raticate-Alola',
    nameKo: '알로라레트라',
    code: '0020-Alola',
    type: ['normal', 'dark'],
    url: '',
  },
  {
    name: 'Spearow',
    nameKo: '깨비참',
    code: '0021',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Fearow',
    nameKo: '깨비드릴조',
    code: '0022',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Ekans',
    nameKo: '아보',
    code: '0023',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Arbok',
    nameKo: '아보크',
    code: '0024',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Pikachu',
    nameKo: '피카츄',
    code: '0025',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-Original',
    nameKo: '피카츄-오리지널캡',
    code: '0025-Original',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-Hoenn',
    nameKo: '피카츄-호연캡',
    code: '0025-Hoenn',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-Sinnoh',
    nameKo: '피카츄-신오캡',
    code: '0025-Sinnoh',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-Unova',
    nameKo: '피카츄-하나캡',
    code: '0025-Unova',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-Kalos',
    nameKo: '피카츄-칼로스캡',
    code: '0025-Kalos',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-Alola',
    nameKo: '피카츄-알로라캡',
    code: '0025-Alola',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-KimiNiKimeta',
    nameKo: '피카츄-너로정했다캡',
    code: '0025-KimiNiKimeta',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pikachu-World',
    nameKo: '피카츄-월드캡',
    code: '0025-World',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Raichu',
    nameKo: '라이츄',
    code: '0026',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Raichu-Alola',
    nameKo: '알로라라이츄',
    code: '0026-Alola',
    type: ['electric', 'psychic'],
    url: '',
  },
  {
    name: 'Sandshrew',
    nameKo: '모래두지',
    code: '0027',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Sandshrew-Alola',
    nameKo: '알로라모래두지',
    code: '0027-Alola',
    type: ['ice', 'steel'],
    url: '',
  },
  {
    name: 'Sandslash',
    nameKo: '고지',
    code: '0028',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Sandslash-Alola',
    nameKo: '알로라고지',
    code: '0028-Alola',
    type: ['ice', 'steel'],
    url: '',
  },
  {
    name: 'Nidoran-F',
    nameKo: '니드런♀',
    code: '0029',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Nidorina',
    nameKo: '니드리나',
    code: '0030',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Nidoqueen',
    nameKo: '니드퀸',
    code: '0031',
    type: ['poison', 'ground'],
    url: '',
  },
  {
    name: 'Nidoran-M',
    nameKo: '니드런♂',
    code: '0032',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Nidorino',
    nameKo: '니드리노',
    code: '0033',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Nidoking',
    nameKo: '니드킹',
    code: '0034',
    type: ['poison', 'ground'],
    url: '',
  },
  {
    name: 'Clefairy',
    nameKo: '삐삐',
    code: '0035',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Clefable',
    nameKo: '픽시',
    code: '0036',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Vulpix',
    nameKo: '식스테일',
    code: '0037',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Vulpix-Alola',
    nameKo: '알로라식스테일',
    code: '0037-Alola',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Ninetales',
    nameKo: '나인테일',
    code: '0038',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Ninetales-Alola',
    nameKo: '알로라나인테일',
    code: '0038-Alola',
    type: ['ice', 'fairy'],
    url: '',
  },
  {
    name: 'Jigglypuff',
    nameKo: '푸린',
    code: '0039',
    type: ['normal', 'fairy'],
    url: '',
  },
  {
    name: 'Wigglytuff',
    nameKo: '푸크린',
    code: '0040',
    type: ['normal', 'fairy'],
    url: '',
  },
  {
    name: 'Zubat',
    nameKo: '주뱃',
    code: '0041',
    type: ['poison', 'flying'],
    url: '',
  },
  {
    name: 'Golbat',
    nameKo: '골뱃',
    code: '0042',
    type: ['poison', 'flying'],
    url: '',
  },
  {
    name: 'Oddish',
    nameKo: '뚜벅쵸',
    code: '0043',
    type: ['poison', 'grass'],
    url: '',
  },
  {
    name: 'Gloom',
    nameKo: '냄새꼬',
    code: '0044',
    type: ['poison', 'grass'],
    url: '',
  },
  {
    name: 'Vileplume',
    nameKo: '라플레시아',
    code: '0045',
    type: ['poison', 'grass'],
    url: '',
  },
  {
    name: 'Paras',
    nameKo: '파라스',
    code: '0046',
    type: ['bug', 'grass'],
    url: '',
  },
  {
    name: 'Parasect',
    nameKo: '파라섹트',
    code: '0047',
    type: ['bug', 'grass'],
    url: '',
  },
  {
    name: 'Venonat',
    nameKo: '콘팡',
    code: '0048',
    type: ['poison', 'bug'],
    url: '',
  },
  {
    name: 'Venomoth',
    nameKo: '도나리',
    code: '0049',
    type: ['poison', 'bug'],
    url: '',
  },
  {
    name: 'Diglett',
    nameKo: '디그다',
    code: '0050',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Diglett-Alola',
    nameKo: '알로라디그다',
    code: '0050-Alola',
    type: ['ground', 'steel'],
    url: '',
  },
  {
    name: 'Dugtrio',
    nameKo: '닥트리오',
    code: '0051',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Dugtrio-Alola',
    nameKo: '알로라닥트리오',
    code: '0051-Alola',
    type: ['ground', 'steel'],
    url: '',
  },
  {
    name: 'Meowth',
    nameKo: '나옹',
    code: '0052',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Meowth-Alola',
    nameKo: '알로라나옹',
    code: '0052-Alola',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Meowth-Galar',
    nameKo: '가라르나옹',
    code: '0052-Galar',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Persian',
    nameKo: '페르시온',
    code: '0053',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Persian-Alola',
    nameKo: '알로라페르시온',
    code: '0053-Alola',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Psyduck',
    nameKo: '고라파덕',
    code: '0054',
    type: ['water'],
    url: '',
  },
  {
    name: 'Golduck',
    nameKo: '골덕',
    code: '0055',
    type: ['water'],
    url: '',
  },
  {
    name: 'Mankey',
    nameKo: '망키',
    code: '0056',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Primeape',
    nameKo: '성원숭',
    code: '0057',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Growlithe',
    nameKo: '가디',
    code: '0058',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Growlithe-Hisui',
    nameKo: '히스이가디',
    code: '0058-Hisui',
    type: ['fire', 'rock'],
    url: '',
  },
  {
    name: 'Arcanine',
    nameKo: '윈디',
    code: '0059',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Arcanine-Hisui',
    nameKo: '히스이윈디',
    code: '0059-Hisui',
    type: ['fire', 'rock'],
    url: '',
  },
  {
    name: 'Poliwag',
    nameKo: '발챙이',
    code: '0060',
    type: ['water'],
    url: '',
  },
  {
    name: 'Poliwhirl',
    nameKo: '슈륙챙이',
    code: '0061',
    type: ['water'],
    url: '',
  },
  {
    name: 'Poliwrath',
    nameKo: '강챙이',
    code: '0062',
    type: ['water', 'fighting'],
    url: '',
  },
  {
    name: 'Abra',
    nameKo: '케이시',
    code: '0063',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Kadabra',
    nameKo: '윤겔라',
    code: '0064',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Alakazam',
    nameKo: '후딘',
    code: '0065',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Alakazam-Mega',
    nameKo: '메가후딘',
    code: '0065-m',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Machop',
    nameKo: '알통몬',
    code: '0066',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Machoke',
    nameKo: '근육몬',
    code: '0067',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Machamp',
    nameKo: '괴력몬',
    code: '0068',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Bellsprout',
    nameKo: '모다피',
    code: '0069',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Weepinbell',
    nameKo: '우츠동',
    code: '0070',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Victreebel',
    nameKo: '우츠보트',
    code: '0071',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Tentacool',
    nameKo: '왕눈해',
    code: '0072',
    type: ['water', 'poison'],
    url: '',
  },
  {
    name: 'Tentacruel',
    nameKo: '독파리',
    code: '0073',
    type: ['water', 'poison'],
    url: '',
  },
  {
    name: 'Geodude',
    nameKo: '꼬마돌',
    code: '0074',
    type: ['rock', 'ground'],
    url: '',
  },
  {
    name: 'Geodude-Alola',
    nameKo: '알로라꼬마돌',
    code: '0075-Alola',
    type: ['rock', 'electric'],
    url: '',
  },
  {
    name: 'Graveler',
    nameKo: '데구리',
    code: '',
    type: ['rock', 'ground'],
    url: '',
  },
  {
    name: 'Graveler-Alola',
    nameKo: '알로라데구리',
    code: '',
    type: ['rock', 'electric'],
    url: '',
  },
  {
    name: 'Golem',
    nameKo: '딱구리',
    code: '',
    type: ['rock', 'ground'],
    url: '',
  },
  {
    name: 'Golem-Alola',
    nameKo: '알로라딱구리',
    code: '',
    type: ['rock', 'electric'],
    url: '',
  },
  {
    name: 'Ponyta',
    nameKo: '포니타',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Ponyta-Galar',
    nameKo: '가라르포니타',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Rapidash',
    nameKo: '날쌩마',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Rapidash-Galar',
    nameKo: '가라르날쌩마',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Slowpoke',
    nameKo: '야돈',
    code: '',
    type: ['water', 'psychic'],
    url: '',
  },
  {
    name: 'Slowpoke-Galar',
    nameKo: '가라르야돈',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Slowbro',
    nameKo: '야도란',
    code: '',
    type: ['water', 'psychic'],
    url: '',
  },
  {
    name: 'Slowbro-Mega',
    nameKo: '메가야도란',
    code: '',
    type: ['water', 'psychic'],
    url: '',
  },
  {
    name: 'Slowbro-Galar',
    nameKo: '가라르야도란',
    code: '',
    type: ['poison', 'psychic'],
    url: '',
  },
  {
    name: 'Magnemite',
    nameKo: '코일',
    code: '',
    type: ['electric', 'steel'],
    url: '',
  },
  {
    name: 'Magneton',
    nameKo: '레어코일',
    code: '',
    type: ['electric', 'steel'],
    url: '',
  },
  {
    name: "Farfetch'd",
    nameKo: '파오리',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: "Farfetch'd-Galar",
    nameKo: '가라르파오리',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Doduo',
    nameKo: '두두',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Dodrio',
    nameKo: '두트리오',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Seel',
    nameKo: '쥬쥬',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Dewgong',
    nameKo: '쥬레곤',
    code: '',
    type: ['water', 'ice'],
    url: '',
  },
  {
    name: 'Grimer',
    nameKo: '질퍽이',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Grimer-Alola',
    nameKo: '알로라질퍽이',
    code: '',
    type: ['poison', 'dark'],
    url: '',
  },
  {
    name: 'Muk',
    nameKo: '질뻐기',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Muk-Alola',
    nameKo: '알로라질뻐기',
    code: '',
    type: ['poison', 'dark'],
    url: '',
  },
  {
    name: 'Shellder',
    nameKo: '셀러',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Cloyster',
    nameKo: '파르셀',
    code: '',
    type: ['water', 'ice'],
    url: '',
  },
  {
    name: 'Gastly',
    nameKo: '고오스',
    code: '',
    type: ['ghost', 'poison'],
    url: '',
  },
  {
    name: 'Haunter',
    nameKo: '고우스트',
    code: '',
    type: ['ghost', 'poison'],
    url: '',
  },
  {
    name: 'Gengar',
    nameKo: '팬텀',
    code: '',
    type: ['ghost', 'poison'],
    url: '',
  },
  {
    name: 'Gengar-Mega',
    nameKo: '메가팬텀',
    code: '',
    type: ['ghost', 'poison'],
    url: '',
  },
  {
    name: 'Onix',
    nameKo: '롱스톤',
    code: '',
    type: ['rock', 'ground'],
    url: '',
  },
  {
    name: 'Drowzee',
    nameKo: '슬리프',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Hypno',
    nameKo: '슬리퍼',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Krabby',
    nameKo: '크랩',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Kingler',
    nameKo: '킹크랩',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Voltorb',
    nameKo: '찌리리공',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Voltorb-Hisui',
    nameKo: '히스이찌리리공',
    code: '',
    type: ['electric', 'grass'],
    url: '',
  },
  {
    name: 'Electrode',
    nameKo: '붐볼',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Electrode-Hisui',
    nameKo: '히스이붐볼',
    code: '',
    type: ['electric', 'grass'],
    url: '',
  },
  {
    name: 'Exeggcute',
    nameKo: '아라리',
    code: '',
    type: ['grass', 'psychic'],
    url: '',
  },
  {
    name: 'Exeggutor',
    nameKo: '나시',
    code: '',
    type: ['grass', 'psychic'],
    url: '',
  },
  {
    name: 'Exeggutor-Alola',
    nameKo: '알로라나시',
    code: '',
    type: ['grass', 'dragon'],
    url: '',
  },
  {
    name: 'Cubone',
    nameKo: '탕구리',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Marowak',
    nameKo: '텅구리',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Marowak-Alola',
    nameKo: '알로라텅구리',
    code: '',
    type: ['fire', 'ghost'],
    url: '',
  },
  {
    name: 'Hitmonlee',
    nameKo: '시라소몬',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Hitmonchan',
    nameKo: '홍수몬',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Lickitung',
    nameKo: '내루미',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Koffing',
    nameKo: '또가스',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Weezing',
    nameKo: '또도가스',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Weezing-Galar',
    nameKo: '가라르또도가스',
    code: '',
    type: ['poison', 'fairy'],
    url: '',
  },
  {
    name: 'Rhyhorn',
    nameKo: '뿔카노',
    code: '',
    type: ['ground', 'rock'],
    url: '',
  },
  {
    name: 'Rhydon',
    nameKo: '코뿌리',
    code: '',
    type: ['ground', 'rock'],
    url: '',
  },
  {
    name: 'Chansey',
    nameKo: '럭키',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Tangela',
    nameKo: '덩쿠리',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Kangaskhan',
    nameKo: '캥카',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Kangaskhan-Mega',
    nameKo: '메가캥카',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Horsea',
    nameKo: '쏘드라',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Seedra',
    nameKo: '시드라',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Goldeen',
    nameKo: '콘치',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Seaking',
    nameKo: '왕콘치',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Staryu',
    nameKo: '별가사리',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Starmie',
    nameKo: '아쿠스타',
    code: '',
    type: ['water', 'psychic'],
    url: '',
  },
  {
    name: 'Mr.Mime',
    nameKo: '마임맨',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Mr.Mime-Galar',
    nameKo: '가라르마임맨',
    code: '',
    type: ['ice', 'psychic'],
    url: '',
  },
  {
    name: 'Scyther',
    nameKo: '스라크',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Jynx',
    nameKo: '루주라',
    code: '',
    type: ['ice', 'psychic'],
    url: '',
  },
  {
    name: 'Electabuzz',
    nameKo: '에레브',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Magmar',
    nameKo: '마그마',
    code: '',
    type: ['fire'],
    url: '',
  },

  {
    name: 'Pinsir',
    nameKo: '쁘사이저',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Pinsir-Maga',
    nameKo: '메가쁘사이저',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Tauros',
    nameKo: '켄타로스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Tauros-Paldea-Combat',
    nameKo: '팔데아켄타로스-컴뱃종',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Tauros-Paldea-Blaze',
    nameKo: '팔데아켄타로스-블레이즈종',
    code: '',
    type: ['fighting', 'fire'],
    url: '',
  },
  {
    name: 'Tauros-Paldea-Aqua',
    nameKo: '팔데아켄타로스-워터종',
    code: '',
    type: ['fighting', 'water'],
    url: '',
  },
  {
    name: 'Magikarp',
    nameKo: '잉어킹',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Gyarados',
    nameKo: '갸라도스',
    code: '',
    type: ['water', 'flying'],
    url: '',
  },
  {
    name: 'Gyarados-Mega',
    nameKo: '메가갸라도스',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Lapras',
    nameKo: '라프라스',
    code: '',
    type: ['water', 'ice'],
    url: '',
  },
  {
    name: 'Ditto',
    nameKo: '메타몽',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Eevee',
    nameKo: '이브이',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Vaporeon',
    nameKo: '샤미드',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Jolteon',
    nameKo: '쥬피썬더',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Flareon',
    nameKo: '부스터',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Porygon',
    nameKo: '폴리곤',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Omanyte',
    nameKo: '암나이트',
    code: '',
    type: ['rock', 'water'],
    url: '',
  },
  {
    name: 'Omastar',
    nameKo: '암스타',
    code: '',
    type: ['rock', 'water'],
    url: '',
  },
  {
    name: 'Kabuto',
    nameKo: '투구',
    code: '',
    type: ['rock', 'water'],
    url: '',
  },
  {
    name: 'Kabutops',
    nameKo: '투구푸스',
    code: '',
    type: ['rock', 'water'],
    url: '',
  },
  {
    name: 'Aerodactyl',
    nameKo: '프테라',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Aerodactyl-Mega',
    nameKo: '메가프테라',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Snorlax',
    nameKo: '잠만보',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Articuno',
    nameKo: '프리져',
    code: '',
    type: ['ice', 'flying'],
    url: '',
  },
  {
    name: 'Articuno-Galar',
    nameKo: '가라르프리져',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Zapdos',
    nameKo: '썬더',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: 'Zapdos-Galar',
    nameKo: '가라르썬더',
    code: '',
    type: ['fighting', 'flying'],
    url: '',
  },
  {
    name: 'Moltres',
    nameKo: '파이어',
    code: '',
    type: ['fire', 'flying'],
    url: '',
  },
  {
    name: 'Zapdos-Galar',
    nameKo: '가라르파이어',
    code: '',
    type: ['dark', 'flying'],
    url: '',
  },
  {
    name: 'Dratini',
    nameKo: '미뇽',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Dragonair',
    nameKo: '신뇽',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Dragonite',
    nameKo: '망나뇽',
    code: '',
    type: ['dragon', 'flying'],
    url: '',
  },
  {
    name: 'Mewtwo',
    nameKo: '뮤츠',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Mewtwo-Mega-X',
    nameKo: '메가뮤츠X',
    code: '',
    type: ['psychic', 'fighting'],
    url: '',
  },
  {
    name: 'Mewtwo-Mega-Y',
    nameKo: '메가뮤츠Y',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Mew',
    nameKo: '뮤',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Chikorita',
    nameKo: '치코리타',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Bayleaf',
    nameKo: '베이리프',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Meganium',
    nameKo: '메가니움',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Cyndaquill',
    nameKo: '브케인',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Quilava',
    nameKo: '마그케인',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Typhlosion',
    nameKo: '블레이범',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Typhlosion-Hisui',
    nameKo: '히스이블레이범',
    code: '',
    type: ['fire', 'ghost'],
    url: '',
  },
  {
    name: 'Totodile',
    nameKo: '리아코',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Croconaw',
    nameKo: '엘리게이',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Feraligatr',
    nameKo: '장크로다일',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Sentret',
    nameKo: '꼬리선',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furret',
    nameKo: '다꼬리',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Hoothoot',
    nameKo: '부우부',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Noctowl',
    nameKo: '야부엉',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Ledyba',
    nameKo: '레디바',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Ledian',
    nameKo: '레디안',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Spinarak',
    nameKo: '페이검',
    code: '',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Ariados',
    nameKo: '아리아도스',
    code: '',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Crobat',
    nameKo: '크로뱃',
    code: '',
    type: ['poison', 'flying'],
    url: '',
  },
  {
    name: 'Chinchou',
    nameKo: '초라기',
    code: '',
    type: ['water', 'electric'],
    url: '',
  },
  {
    name: 'Lanturn',
    nameKo: '랜턴',
    code: '',
    type: ['water', 'electric'],
    url: '',
  },
  {
    name: 'Pichu',
    nameKo: '피츄',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Cleffa',
    nameKo: '삐',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Igglybuff',
    nameKo: '푸푸린',
    code: '',
    type: ['normal', 'fairy'],
    url: '',
  },
  {
    name: 'Togepi',
    nameKo: '토게피',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Togetic',
    nameKo: '토게틱',
    code: '',
    type: ['fairy', 'flying'],
    url: '',
  },
  {
    name: 'Natu',
    nameKo: '네이티',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Xatu',
    nameKo: '네이티오',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Mareep',
    nameKo: '메리프',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Fleaffy',
    nameKo: '보송송',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Ampharos',
    nameKo: '전룡',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Ampharos-Mega',
    nameKo: '메가전룡',
    code: '',
    type: ['electric', 'dragon'],
    url: '',
  },
  {
    name: 'Bellossom',
    nameKo: '아르코',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Marill',
    nameKo: '마릴',
    code: '',
    type: ['water', 'fairy'],
    url: '',
  },
  {
    name: 'Azumarill',
    nameKo: '마릴리',
    code: '',
    type: ['water', 'fairy'],
    url: '',
  },
  {
    name: 'Sudowoodo',
    nameKo: '꼬지모',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Politoed',
    nameKo: '왕구리',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Hoppip',
    nameKo: '통통코',
    code: '',
    type: ['grass', 'flying'],
    url: '',
  },
  {
    name: 'Skiploom',
    nameKo: '두코',
    code: '',
    type: ['grass', 'flying'],
    url: '',
  },
  {
    name: 'Jumpluff',
    nameKo: '솜솜코',
    code: '',
    type: ['grass', 'flying'],
    url: '',
  },
  {
    name: 'Aipom',
    nameKo: '에이팜',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Sunkern',
    nameKo: '해너츠',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Sunflora',
    nameKo: '해루미',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Yanma',
    nameKo: '왕자리',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Wooper',
    nameKo: '우파',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Wooper-Paldea',
    nameKo: '팔데아우파',
    code: '',
    type: ['poison', 'ground'],
    url: '',
  },
  {
    name: 'Quagsire',
    nameKo: '누오',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Espeon',
    nameKo: '에브이',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Umbreon',
    nameKo: '블래키',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Murkrow',
    nameKo: '니로우',
    code: '',
    type: ['dark', 'flying'],
    url: '',
  },
  {
    name: 'Slowking',
    nameKo: '야도킹',
    code: '',
    type: ['water', 'psychic'],
    url: '',
  },
  {
    name: 'Slowking-Galar',
    nameKo: '가라르야도킹',
    code: '',
    type: ['poison', 'psychic'],
    url: '',
  },
  {
    name: 'Misdreavus',
    nameKo: '무우마',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Unown-A',
    nameKo: '안농A',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-B',
    nameKo: '안농B',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-C',
    nameKo: '안농C',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-D',
    nameKo: '안농D',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-E',
    nameKo: '안농E',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-F',
    nameKo: '안농F',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-G',
    nameKo: '안농G',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-H',
    nameKo: '안농H',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-I',
    nameKo: '안농I',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-J',
    nameKo: '안농J',
    code: '',
    type: ['psychic'],
    url: '',
  },

  {
    name: 'Unown-K',
    nameKo: '안농K',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-L',
    nameKo: '안농L',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-M',
    nameKo: '안농M',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-N',
    nameKo: '안농N',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-O',
    nameKo: '안농O',
    code: '',
    type: ['psychic'],
    url: '',
  },

  {
    name: 'Unown-P',
    nameKo: '안농P',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-Q',
    nameKo: '안농Q',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-R',
    nameKo: '안농R',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-S',
    nameKo: '안농S',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-T',
    nameKo: '안농T',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-U',
    nameKo: '안농U',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-V',
    nameKo: '안농V',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-W',
    nameKo: '안농W',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-X',
    nameKo: '안농X',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-Y',
    nameKo: '안농Y',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-Z',
    nameKo: '안농Z',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-!',
    nameKo: '안농!',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Unown-?',
    nameKo: '안농?',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Wobuffet',
    nameKo: '마자용',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Girafarig',
    nameKo: '키링키',
    code: '',
    type: ['normal', 'psychic'],
    url: '',
  },
  {
    name: 'Pineco',
    nameKo: '피콘',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Forretress',
    nameKo: '쏘콘',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Dunsparce',
    nameKo: '노고치',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Gligar',
    nameKo: '글라이거',
    code: '',
    type: ['ground', 'flying'],
    url: '',
  },
  {
    name: 'Steelix',
    nameKo: '강철톤',
    code: '',
    type: ['steel', 'ground'],
    url: '',
  },
  {
    name: 'Steelix-Mega',
    nameKo: '메가강철톤',
    code: '',
    type: ['steel', 'ground'],
    url: '',
  },
  {
    name: 'Snubbull',
    nameKo: '블루',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Granbull',
    nameKo: '그랑블루',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Qwilfish',
    nameKo: '침바루',
    code: '',
    type: ['water', 'poison'],
    url: '',
  },
  {
    name: 'Qwilfish-Hisui',
    nameKo: '히스이침바루',
    code: '',
    type: ['dark', 'poison'],
    url: '',
  },
  {
    name: 'Scizor',
    nameKo: '핫삼',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Scizor-Mega',
    nameKo: '메가핫삼',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Shuckle',
    nameKo: '단단지',
    code: '',
    type: ['bug', 'rock'],
    url: '',
  },
  {
    name: 'Heracross',
    nameKo: '헤라크로스',
    code: '',
    type: ['bug', 'fighting'],
    url: '',
  },
  {
    name: 'Heracross-Mega',
    nameKo: '메가헤라크로스',
    code: '',
    type: ['bug', 'fighting'],
    url: '',
  },
  {
    name: 'Sneasel',
    nameKo: '포푸니',
    code: '',
    type: ['dark', 'ice'],
    url: '',
  },
  {
    name: 'Sneasel-Hisui',
    nameKo: '히스이포푸니',
    code: '',
    type: ['fighting', 'poison'],
    url: '',
  },
  {
    name: 'Teddiursa',
    nameKo: '깜지곰',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Ursaring',
    nameKo: '링곰',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Slugma',
    nameKo: '마그마그',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Magcargo',
    nameKo: '마그카르고',
    code: '',
    type: ['fire', 'rock'],
    url: '',
  },
  {
    name: 'Swinub',
    nameKo: '꾸꾸리',
    code: '',
    type: ['ice', 'ground'],
    url: '',
  },
  {
    name: 'Piloswine',
    nameKo: '메꾸리',
    code: '',
    type: ['ice', 'ground'],
    url: '',
  },
  {
    name: 'Corsola',
    nameKo: '코산호',
    code: '',
    type: ['water', 'rock'],
    url: '',
  },
  {
    name: 'Corsola-Galar',
    nameKo: '가라르코산호',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Remoraid',
    nameKo: '총어',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Octillery',
    nameKo: '대포무노',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Delibird',
    nameKo: '딜리버드',
    code: '',
    type: ['ice', 'flying'],
    url: '',
  },
  {
    name: 'Mantine',
    nameKo: '만타인',
    code: '',
    type: ['water', 'flying'],
    url: '',
  },
  {
    name: 'Skarmory',
    nameKo: '무장조',
    code: '',
    type: ['steel', 'flying'],
    url: '',
  },
  {
    name: 'Houndour',
    nameKo: '델빌',
    code: '',
    type: ['dark', 'fire'],
    url: '',
  },
  {
    name: 'Houndoom',
    nameKo: '헬가',
    code: '',
    type: ['dark', 'fire'],
    url: '',
  },
  {
    name: 'Houndoom-Mega',
    nameKo: '메가헬가',
    code: '',
    type: ['dark', 'fire'],
    url: '',
  },
  {
    name: 'Kingdra',
    nameKo: '킹드라',
    code: '',
    type: ['water', 'dragon'],
    url: '',
  },
  {
    name: 'Phanpy',
    nameKo: '코코리',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Donphan',
    nameKo: '코리갑',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Porygon2',
    nameKo: '폴리곤2',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Stantler',
    nameKo: '노라키',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Smeargle',
    nameKo: '루브도',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Tyrogue',
    nameKo: '배루키',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Hitmontop',
    nameKo: '카포에라',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Smoochum',
    nameKo: '뽀뽀라',
    code: '',
    type: ['ice', 'psychic'],
    url: '',
  },
  {
    name: 'Elekid',
    nameKo: '에레키드',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Magby',
    nameKo: '마그비',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Miltank',
    nameKo: '밀탱크',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Blissey',
    nameKo: '해피너스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Raikou',
    nameKo: '라이코',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Entei',
    nameKo: '앤테이',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Suicune',
    nameKo: '스이쿤',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Larvitar',
    nameKo: '애버라스',
    code: '',
    type: ['rock', 'ground'],
    url: '',
  },
  {
    name: 'Pupitar',
    nameKo: '데기라스',
    code: '',
    type: ['rock', 'ground'],
    url: '',
  },
  {
    name: 'Tyranitar',
    nameKo: '마기라스',
    code: '',
    type: ['rock', 'dark'],
    url: '',
  },
  {
    name: 'Tyranitar-Mega',
    nameKo: '메가마기라스',
    code: '',
    type: ['rock', 'dark'],
    url: '',
  },
  {
    name: 'Lugia',
    nameKo: '루기아',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Ho-Oh',
    nameKo: '칠색조',
    code: '',
    type: ['fire', 'flying'],
    url: '',
  },
  {
    name: 'Celebi',
    nameKo: '세레비',
    code: '',
    type: ['psychic', 'grass'],
    url: '',
  },
  {
    name: 'Treecko',
    nameKo: '나무지기',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Grovyle',
    nameKo: '나무돌이',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Sceptile',
    nameKo: '나무킹',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Sceptile-Mega',
    nameKo: '메가나무킹',
    code: '',
    type: ['grass', 'dragon'],
    url: '',
  },
  {
    name: 'Torchic',
    nameKo: '아차모',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Combusken',
    nameKo: '영치코',
    code: '',
    type: ['fire', 'fighting'],
    url: '',
  },
  {
    name: 'Blaziken',
    nameKo: '번치코',
    code: '',
    type: ['fire', 'fighting'],
    url: '',
  },
  {
    name: 'Blaziken-Mega',
    nameKo: '메가번치코',
    code: '',
    type: ['fire', 'fighting'],
    url: '',
  },
  {
    name: 'Mudkip',
    nameKo: '물짱이',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Marshtomp',
    nameKo: '늪짱이',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Swampert',
    nameKo: '대짱이',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Swampert-Mega',
    nameKo: '메가대짱이',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Poochyena',
    nameKo: '포챠나',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Mightyena',
    nameKo: '그라에나',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Zigzagoon',
    nameKo: '지그제구리',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Zigzagoon-Galar',
    nameKo: '가라르지그제구리',
    code: '',
    type: ['normal', 'dark'],
    url: '',
  },
  {
    name: 'Linoone',
    nameKo: '직구리',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Linoone-Galar',
    nameKo: '가라르직구리',
    code: '',
    type: ['normal', 'dark'],
    url: '',
  },
  {
    name: 'Wurmple',
    nameKo: '개무소',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Silcoon',
    nameKo: '실쿤',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Beautifly',
    nameKo: '뷰티플라이',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Cascoon',
    nameKo: '카스쿤',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Dustox',
    nameKo: '독케일',
    code: '',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Lotad',
    nameKo: '연꽃몬',
    code: '',
    type: ['water', 'grass'],
    url: '',
  },
  {
    name: 'Lombre',
    nameKo: '로토스',
    code: '',
    type: ['water', 'grass'],
    url: '',
  },
  {
    name: 'Ludicolo',
    nameKo: '로파파',
    code: '',
    type: ['water', 'grass'],
    url: '',
  },
  {
    name: 'Seedot',
    nameKo: '도토링',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Nuzleaf',
    nameKo: '잎새코',
    code: '',
    type: ['grass', 'dark'],
    url: '',
  },
  {
    name: 'Shiftry',
    nameKo: '다탱구',
    code: '',
    type: ['grass', 'dark'],
    url: '',
  },
  {
    name: 'Tailow',
    nameKo: '테일로',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Swellow',
    nameKo: '스왈로',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Wingull',
    nameKo: '갈모매',
    code: '',
    type: ['water', 'flying'],
    url: '',
  },
  {
    name: 'Pelipper',
    nameKo: '패리퍼',
    code: '',
    type: ['water', 'flying'],
    url: '',
  },
  {
    name: 'Ralts',
    nameKo: '랄토스',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Kirlia',
    nameKo: '킬리아',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Gardevoir',
    nameKo: '가디안',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Gardevoir-Mega',
    nameKo: '메가가디안',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Surskit',
    nameKo: '비구술',
    code: '',
    type: ['bug', 'water'],
    url: '',
  },
  {
    name: 'Masquerain',
    nameKo: '비나방',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Shroomish',
    nameKo: '버섯꼬',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Breloom',
    nameKo: '버섯모',
    code: '',
    type: ['grass', 'fighting'],
    url: '',
  },
  {
    name: 'Slakoth',
    nameKo: '게을로',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Vigoroth',
    nameKo: '발바로',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Slaking',
    nameKo: '게을킹',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Nincada',
    nameKo: '토중몬',
    code: '',
    type: ['bug', 'ground'],
    url: '',
  },
  {
    name: 'Ninjask',
    nameKo: '아이스크',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Shedinja',
    nameKo: '껍질몬',
    code: '',
    type: ['bug', 'ghost'],
    url: '',
  },
  {
    name: 'Whismur',
    nameKo: '소곤룡',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Loudred',
    nameKo: '노공룡',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Exploud',
    nameKo: '폭음룡',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Makuhita',
    nameKo: '마크탕',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Hariyama',
    nameKo: '하리뭉',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Azurill',
    nameKo: '루리리',
    code: '',
    type: ['normal', 'fairy'],
    url: '',
  },
  {
    name: 'Nosepass',
    nameKo: '코코파스',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Skitty',
    nameKo: '에나비',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Delcatty',
    nameKo: '델케티',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Sableye',
    nameKo: '깜까미',
    code: '',
    type: ['dark', 'ghost'],
    url: '',
  },
  {
    name: 'Sableye-Mega',
    nameKo: '메가깜까미',
    code: '',
    type: ['dark', 'ghost'],
    url: '',
  },
  {
    name: 'Mawile',
    nameKo: '입치트',
    code: '',
    type: ['steel', 'fairy'],
    url: '',
  },
  {
    name: 'Mawile-Mega',
    nameKo: '메가입치트',
    code: '',
    type: ['steel', 'fairy'],
    url: '',
  },
  {
    name: 'Aron',
    nameKo: '가보리',
    code: '',
    type: ['steel', 'rock'],
    url: '',
  },
  {
    name: 'Lairon',
    nameKo: '갱도라',
    code: '',
    type: ['steel', 'rock'],
    url: '',
  },
  {
    name: 'Aggron',
    nameKo: '보스로라',
    code: '',
    type: ['steel', 'rock'],
    url: '',
  },
  {
    name: 'Aggron-Mega',
    nameKo: '메가보스로라',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Meditite',
    nameKo: '요가랑',
    code: '',
    type: ['fighting', 'psychic'],
    url: '',
  },
  {
    name: 'Medicham',
    nameKo: '요가램',
    code: '',
    type: ['fighting', 'psychic'],
    url: '',
  },
  {
    name: 'Medicham-Mega',
    nameKo: '메가요가램',
    code: '',
    type: ['fighting', 'psychic'],
    url: '',
  },
  {
    name: 'Electrike',
    nameKo: '썬더라이',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Manectirc',
    nameKo: '썬더볼트',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Manectric-Mega',
    nameKo: '메가썬더볼트',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Plusle',
    nameKo: '플러시',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Minun',
    nameKo: '마이농',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Volbeat',
    nameKo: '볼비트',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Illumise',
    nameKo: '네오비트',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Roselia',
    nameKo: '로젤리아',
    code: '',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Gulpin',
    nameKo: '꼴깍몬',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Swalot',
    nameKo: '꿀꺽몬',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Carvanha',
    nameKo: '샤프니아',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Sharpedo',
    nameKo: '샤크니아',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Sharpedo-Mega',
    nameKo: '메가샤크니아',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Wailmer',
    nameKo: '고래왕자',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Wailord',
    nameKo: '고래왕',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Numel',
    nameKo: '둔타',
    code: '',
    type: ['fire', 'ground'],
    url: '',
  },
  {
    name: 'Camerupt',
    nameKo: '폭타',
    code: '',
    type: ['fire', 'ground'],
    url: '',
  },
  {
    name: 'Camerupt-Mega',
    nameKo: '메가폭타',
    code: '',
    type: ['fire', 'ground'],
    url: '',
  },
  {
    name: 'Torkoal',
    nameKo: '코터스',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Spoink',
    nameKo: '피그점프',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Grumpig',
    nameKo: '피그킹',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Spinda',
    nameKo: '얼루기',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Trapinch',
    nameKo: '톱치',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Vibrava',
    nameKo: '비브라바',
    code: '',
    type: ['ground', 'dragon'],
    url: '',
  },
  {
    name: 'Flygon',
    nameKo: '플라이곤',
    code: '',
    type: ['ground', 'dragon'],
    url: '',
  },
  {
    name: 'Cacnea',
    nameKo: '선인왕',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Cacturne',
    nameKo: '밤선인',
    code: '',
    type: ['grass', 'dark'],
    url: '',
  },
  {
    name: 'Swablu',
    nameKo: '파비코',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Altaria',
    nameKo: '파비코리',
    code: '',
    type: ['dragon', 'flying'],
    url: '',
  },
  {
    name: 'Altaria-Mega',
    nameKo: '메가파비코리',
    code: '',
    type: ['dragon', 'fairy'],
    url: '',
  },
  {
    name: 'Zangoose',
    nameKo: '쟝고',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Seviper',
    nameKo: '세비퍼',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Lunatone',
    nameKo: '루나톤',
    code: '',
    type: ['rock', 'psychic'],
    url: '',
  },
  {
    name: 'Solrock',
    nameKo: '솔록',
    code: '',
    type: ['rock', 'psychic'],
    url: '',
  },
  {
    name: 'Barboach',
    nameKo: '미꾸리',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Whiscash',
    nameKo: '메깅',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Corphish',
    nameKo: '가재군',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Crawdaunt',
    nameKo: '가재장군',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Baltoy',
    nameKo: '오뚝군',
    code: '',
    type: ['ground', 'psychic'],
    url: '',
  },
  {
    name: 'Claydol',
    nameKo: '점토도리',
    code: '',
    type: ['ground', 'psychic'],
    url: '',
  },
  {
    name: 'Lileep',
    nameKo: '릴링',
    code: '',
    type: ['rock', 'grass'],
    url: '',
  },
  {
    name: 'Cradily',
    nameKo: '릴리요',
    code: '',
    type: ['rock', 'grass'],
    url: '',
  },
  {
    name: 'Anorith',
    nameKo: '아노딥스',
    code: '',
    type: ['rock', 'bug'],
    url: '',
  },
  {
    name: 'Armaldo',
    nameKo: '아말도',
    code: '',
    type: ['rock', 'bug'],
    url: '',
  },
  {
    name: 'Feebas',
    nameKo: '빈티나',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Milotic',
    nameKo: '밀로틱',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Castform',
    nameKo: '캐스퐁',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Castform-Sunny',
    nameKo: '캐스퐁-태양',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Castform-Rainy',
    nameKo: '캐스퐁-빗방울',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Castform-Snowy',
    nameKo: '캐스퐁-설운',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Kacleon',
    nameKo: '켈리몬',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Shuppet',
    nameKo: '어둠대신',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Banette',
    nameKo: '다크펫',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Banette-Mega',
    nameKo: '메가다크펫',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Duskull',
    nameKo: '해골몽',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Dusclops',
    nameKo: '미라몽',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Tropius',
    nameKo: '트로피우스',
    code: '',
    type: ['grass', 'flying'],
    url: '',
  },
  {
    name: 'Chimecho',
    nameKo: '치렁',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Absol',
    nameKo: '앱솔',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Absol-Mega',
    nameKo: '메가앱솔',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Wynaut',
    nameKo: '마자',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Snorunt',
    nameKo: '눈꼬마',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Glalie',
    nameKo: '얼음귀신',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Glalie-Mega',
    nameKo: '메가얼음귀신',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Spheal',
    nameKo: '대굴레오',
    code: '',
    type: ['ice', 'water'],
    url: '',
  },
  {
    name: 'Sealeo',
    nameKo: '씨레오',
    code: '',
    type: ['ice', 'water'],
    url: '',
  },
  {
    name: 'Walrein',
    nameKo: '씨카이저',
    code: '',
    type: ['ice', 'water'],
    url: '',
  },
  {
    name: 'Clamperl',
    nameKo: '진주몽',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Huntail',
    nameKo: '헌테일',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Gorebyss',
    nameKo: '분홍장이',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Relicanth',
    nameKo: '시라칸',
    code: '',
    type: ['water', 'rock'],
    url: '',
  },
  {
    name: 'Luvdisc',
    nameKo: '사랑동이',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Bagon',
    nameKo: '아공이',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Shelgon',
    nameKo: '쉘곤',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Salamence',
    nameKo: '보만다',
    code: '',
    type: ['dragon', 'flying'],
    url: '',
  },
  {
    name: 'Salamence-Mega',
    nameKo: '메가보만다',
    code: '',
    type: ['dragon', 'flying'],
    url: '',
  },
  {
    name: 'Beldum',
    nameKo: '메탕',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Metang',
    nameKo: '메탕구',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Metagross',
    nameKo: '메타그로스',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Metagross-Mega',
    nameKo: '메가메타그로스',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Regirock',
    nameKo: '레지락',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Regice',
    nameKo: '레지아이스',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Registeel',
    nameKo: '레지스틸',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Latias',
    nameKo: '라티아스',
    code: '',
    type: ['dragon', 'psychic'],
    url: '',
  },
  {
    name: 'Latias-Mega',
    nameKo: '메가라티아스',
    code: '',
    type: ['dragon', 'psychic'],
    url: '',
  },
  {
    name: 'Latios',
    nameKo: '라티오스',
    code: '',
    type: ['dragon', 'psychic'],
    url: '',
  },
  {
    name: 'Latios-Mega',
    nameKo: '메가라티오스',
    code: '',
    type: ['dragon', 'psychic'],
    url: '',
  },
  {
    name: 'Kyogre',
    nameKo: '가이오가',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Kyogre-Primal',
    nameKo: '원시가이오가',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Groudon',
    nameKo: '그란돈',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Groudon-Primal',
    nameKo: '원시그란돈',
    code: '',
    type: ['ground', 'fire'],
    url: '',
  },
  {
    name: 'Rayquaza',
    nameKo: '레쿠쟈',
    code: '',
    type: ['dragon', 'flying'],
    url: '',
  },
  {
    name: 'Rayquaza-Mega',
    nameKo: '메가레쿠쟈',
    code: '',
    type: ['dragon', 'flying'],
    url: '',
  },
  {
    name: 'Jirachi',
    nameKo: '지라치',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Deoxys',
    nameKo: '테오키스',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Deoxys-Attack',
    nameKo: '테오키스-어택폼',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Deoxys-Defense',
    nameKo: '테오키스-디펜스폼',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Deoxys-Speed',
    nameKo: '테오키스-스피드폼',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Turtwig',
    nameKo: '모부기',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Grotle',
    nameKo: '수풀부기',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Torterra',
    nameKo: '토대부기',
    code: '',
    type: ['grass', 'ground'],
    url: '',
  },
  {
    name: 'Chimachar',
    nameKo: '불꽃숭이',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Monferno',
    nameKo: '파이숭이',
    code: '',
    type: ['fire', 'fighting'],
    url: '',
  },
  {
    name: 'Infernape',
    nameKo: '초염몽',
    code: '',
    type: ['fire', 'fighting'],
    url: '',
  },
  {
    name: 'Piplup',
    nameKo: '팽도리',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Prinplup',
    nameKo: '팽태자',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Empoleon',
    nameKo: '엠페르트',
    code: '',
    type: ['water', 'steel'],
    url: '',
  },
  {
    name: 'Satarly',
    nameKo: '찌르꼬',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Staravia',
    nameKo: '찌르버드',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Staraptor',
    nameKo: '찌르호크',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Bidoof',
    nameKo: '비버니',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Bibarel',
    nameKo: '비버통',
    code: '',
    type: ['normal', 'water'],
    url: '',
  },
  {
    name: 'Kricketot',
    nameKo: '귀뚤뚜기',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Kricketune',
    nameKo: '귀뚤톡크',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Shinx',
    nameKo: '꼬링크',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Luxio',
    nameKo: '럭시오',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Luxray',
    nameKo: '렌트라',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Budew',
    nameKo: '꼬몽울',
    code: '',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Roserade',
    nameKo: '로즈레이드',
    code: '',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Cranidos',
    nameKo: '두개도스',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Rampardos',
    nameKo: '램펄드',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Shieldon',
    nameKo: '방패톱스',
    code: '',
    type: ['rock', 'steel'],
    url: '',
  },
  {
    name: 'Bastiodon',
    nameKo: '바리톱스',
    code: '',
    type: ['rock', 'steel'],
    url: '',
  },
  {
    name: 'Burmy-Plant',
    nameKo: '도롱충이-초목도롱',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Burmy-Sandy',
    nameKo: '도롱충이-모래땅도롱',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Burmy-Trash',
    nameKo: '도롱충이-슈레도롱',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Wormadam-Plant',
    nameKo: '도롱마담-초목도롱',
    code: '',
    type: ['bug', 'grass'],
    url: '',
  },
  {
    name: 'Wormadam-Sandy',
    nameKo: '도롱마담-모래땅도롱',
    code: '',
    type: ['bug', 'ground'],
    url: '',
  },
  {
    name: 'Wormadam-Trash',
    nameKo: '도롱마담-슈레도롱',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Mothim',
    nameKo: '나메일',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Combee',
    nameKo: '세꿀버리',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vespiquen',
    nameKo: '비퀸',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Pachirisu',
    nameKo: '파치리스',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Buizel',
    nameKo: '브이젤',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Floatzel',
    nameKo: '플로젤',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Cherubi',
    nameKo: '체리버',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Cherrim-Overcast',
    nameKo: '체리꼬-네거폼',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Cherubi-Sunshine',
    nameKo: '체리꼬-포지폼',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Shellos-West',
    nameKo: '깝질무-서쪽',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Shellos-East',
    nameKo: '깝질무-동쪽',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Gastrodon-West',
    nameKo: '트리토돈-서쪽',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Gastrodon-East',
    nameKo: '트리토돈-동쪽',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Ambipom',
    nameKo: '겟핸보숭',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Drifloon',
    nameKo: '흔들풍손',
    code: '',
    type: ['ghost', 'flying'],
    url: '',
  },
  {
    name: 'Drifblim',
    nameKo: '둥실라이드',
    code: '',
    type: ['ghost', 'flying'],
    url: '',
  },
  {
    name: 'Bunearly',
    nameKo: '이어롤',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Lopunny',
    nameKo: '이어롭',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Lopunny-Mega',
    nameKo: '메가이어롭',
    code: '',
    type: ['normal', 'fighting'],
    url: '',
  },
  {
    name: 'Mismagius',
    nameKo: '무우마직',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Honchkrow',
    nameKo: '돈크로우',
    code: '',
    type: ['dark', 'flying'],
    url: '',
  },
  {
    name: 'Glameow',
    nameKo: '나옹마',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Purugly',
    nameKo: '몬냥이',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Chingling',
    nameKo: '랑딸랑',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Stunky',
    nameKo: '스컹뿡',
    code: '',
    type: ['poison', 'dark'],
    url: '',
  },
  {
    name: 'Skuntank',
    nameKo: '스컹탱크',
    code: '',
    type: ['poison', 'dark'],
    url: '',
  },
  {
    name: 'Bronzor',
    nameKo: '동미러',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Bronzong',
    nameKo: '동탁군',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Bonsly',
    nameKo: '꼬지지',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Mime Jr.',
    nameKo: '흉내내',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Happiny',
    nameKo: '핑복',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Chatot',
    nameKo: '페라페',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Spiritomb',
    nameKo: '화강돌',
    code: '',
    type: ['ghost', 'dark'],
    url: '',
  },
  {
    name: 'Gible',
    nameKo: '딥상어동',
    code: '',
    type: ['dragon', 'ground'],
    url: '',
  },
  {
    name: 'Gabite',
    nameKo: '한바이트',
    code: '',
    type: ['dragon', 'ground'],
    url: '',
  },
  {
    name: 'Garchomp',
    nameKo: '한카리아스',
    code: '',
    type: ['dragon', 'ground'],
    url: '',
  },
  {
    name: 'Garchomp-Mega',
    nameKo: '메가한카리아스',
    code: '',
    type: ['dragon', 'ground'],
    url: '',
  },
  {
    name: 'Munchlax',
    nameKo: '먹고자',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Riolu',
    nameKo: '리오르',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Lucario',
    nameKo: '루카리오',
    code: '',
    type: ['fighting', 'steel'],
    url: '',
  },
  {
    name: 'Lucario-Mega',
    nameKo: '메가루카리오',
    code: '',
    type: ['fighting', 'steel'],
    url: '',
  },
  {
    name: 'Hippopotas',
    nameKo: '히포포타스',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Hippowdon',
    nameKo: '하마돈',
    code: '',
    type: ['ground'],
    url: '',
  },

  {
    name: 'Skorupi',
    nameKo: '스콜피',
    code: '',
    type: ['poison', 'bug'],
    url: '',
  },
  {
    name: 'Drapion',
    nameKo: '드래피온',
    code: '',
    type: ['poison', 'dark'],
    url: '',
  },
  {
    name: 'Croagunk',
    nameKo: '삐딱구리',
    code: '',
    type: ['poison', 'fighting'],
    url: '',
  },
  {
    name: 'Toxicroak',
    nameKo: '독개굴',
    code: '',
    type: ['poison', 'fighting'],
    url: '',
  },
  {
    name: 'Carnivine',
    nameKo: '무스틈니',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Finneon',
    nameKo: '형광어',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Lumineon',
    nameKo: '네오라이트',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Mantyke',
    nameKo: '타만타',
    code: '',
    type: ['water', 'flying'],
    url: '',
  },
  {
    name: 'Snover',
    nameKo: '눈쓰개',
    code: '',
    type: ['grass', 'ice'],
    url: '',
  },
  {
    name: 'Abomasnow',
    nameKo: '눈설왕',
    code: '',
    type: ['grass', 'ice'],
    url: '',
  },
  {
    name: 'Abomasnow-Mega',
    nameKo: '메가눈설왕',
    code: '',
    type: ['grass', 'ice'],
    url: '',
  },
  {
    name: 'Weavile',
    nameKo: '포푸니라',
    code: '',
    type: ['dark', 'ice'],
    url: '',
  },
  {
    name: 'Magnezone',
    nameKo: '자포코일',
    code: '',
    type: ['electric', 'steel'],
    url: '',
  },
  {
    name: 'Lickilicky',
    nameKo: '내룸벨트',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Rhyperior',
    nameKo: '거대코뿌리',
    code: '',
    type: ['ground', 'rock'],
    url: '',
  },
  {
    name: 'Tangrowth',
    nameKo: '덩쿠림보',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Electivire',
    nameKo: '에레키블',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Magmortar',
    nameKo: '마그마번',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Togekiss',
    nameKo: '토게키스',
    code: '',
    type: ['fairy', 'flying'],
    url: '',
  },
  {
    name: 'Yanmega',
    nameKo: '메가자리',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Leafeon',
    nameKo: '리피아',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Glaceon',
    nameKo: '글레이시아',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Gliscor',
    nameKo: '글라이온',
    code: '',
    type: ['ground', 'flying'],
    url: '',
  },
  {
    name: 'Mamoswine',
    nameKo: '맘모꾸리',
    code: '',
    type: ['ice', 'ground'],
    url: '',
  },
  {
    name: 'Porygon-Z',
    nameKo: '폴리곤Z',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Gallade',
    nameKo: '엘레이드',
    code: '',
    type: ['psychic', 'fighting'],
    url: '',
  },
  {
    name: 'Gallade-Mega',
    nameKo: '메가엘레이드',
    code: '',
    type: ['psychic', 'fighting'],
    url: '',
  },
  {
    name: 'Probopass',
    nameKo: '대코파스',
    code: '',
    type: ['rock', 'steel'],
    url: '',
  },
  {
    name: 'Dusknoir',
    nameKo: '야느와르몽',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Froslass',
    nameKo: '눈여아',
    code: '',
    type: ['ice', 'ghost'],
    url: '',
  },
  {
    name: 'Rotom',
    nameKo: '로토무',
    code: '',
    type: ['electric', 'ghost'],
    url: '',
  },
  {
    name: 'Rotom-Heat',
    nameKo: '히트로토무',
    code: '',
    type: ['electric', 'fire'],
    url: '',
  },
  {
    name: 'Rotom-Wash',
    nameKo: '워시로토무',
    code: '',
    type: ['electric', 'water'],
    url: '',
  },
  {
    name: 'Rotom-Frost',
    nameKo: '프로스트로토무',
    code: '',
    type: ['electric', 'ice'],
    url: '',
  },
  {
    name: 'Rotom-Fan',
    nameKo: '스핀로토무',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: 'Rotom-Mow',
    nameKo: '커트로토무',
    code: '',
    type: ['electric', 'grass'],
    url: '',
  },
  {
    name: 'Uxie',
    nameKo: '유크시',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Mesprit',
    nameKo: '엠라이트',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Azelf',
    nameKo: '아그놈',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Dialga',
    nameKo: '디아루가',
    code: '',
    type: ['steel', 'dragon'],
    url: '',
  },
  {
    name: 'Dialga-Origin',
    nameKo: '디아루가-오리진폼',
    code: '',
    type: ['steel', 'dragon'],
    url: '',
  },
  {
    name: 'Palgia',
    nameKo: '펄기아',
    code: '',
    type: ['water', 'dragon'],
    url: '',
  },
  {
    name: 'Palgia-Origin',
    nameKo: '펄기아-오리진폼',
    code: '',
    type: ['water', 'dragon'],
    url: '',
  },
  {
    name: 'Heatran',
    nameKo: '히드런',
    code: '',
    type: ['fire', 'steel'],
    url: '',
  },
  {
    name: 'Regigigas',
    nameKo: '레지기가스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Giratina',
    nameKo: '기라티나',
    code: '',
    type: ['ghost', 'dragon'],
    url: '',
  },
  {
    name: 'Giratina-Origin',
    nameKo: '기라티나-오리진',
    code: '',
    type: ['ghost', 'dragon'],
    url: '',
  },
  {
    name: 'Cresselia',
    nameKo: '크레세리아',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Phione',
    nameKo: '피오네',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Manaphy',
    nameKo: '마나피',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Darkrai',
    nameKo: '다크라이',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Shaymin',
    nameKo: '쉐이미-랜드폼',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Shaymin-Sky',
    nameKo: '쉐이미-스카이폼',
    code: '',
    type: ['grass', 'flying'],
    url: '',
  },
  {
    name: 'Arceus',
    nameKo: '아르세우스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Arceus-Fighting',
    nameKo: '아르세우스-격투',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Arceus-Flying',
    nameKo: '아르세우스-비행',
    code: '',
    type: ['flying'],
    url: '',
  },
  {
    name: 'Arceus-poison',
    nameKo: '아르세우스-독',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Arceus-Ground',
    nameKo: '아르세우스-땅',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Arceus-Rock',
    nameKo: '아르세우스-바위',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Arceus-Bug',
    nameKo: '아르세우스-벌레',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Arceus-Ghost',
    nameKo: '아르세우스-고스트',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Arceus-Steel',
    nameKo: '아르세우스-강철',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Arceus-Fire',
    nameKo: '아르세우스-불꽃',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Arceus-Water',
    nameKo: '아르세우스-물',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Arceus-Grass',
    nameKo: '아르세우스-풀',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Arceus-Electric',
    nameKo: '아르세우스-전기',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Arceus-Psychic',
    nameKo: '아르세우스-에스퍼',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Arceus-Ice',
    nameKo: '아르세우스-얼음',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Arceus-Dragon',
    nameKo: '아르세우스-드래곤',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Arceus-Dark',
    nameKo: '아르세우스-악',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Arceus-Fairy',
    nameKo: '아르세우스-페어리',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Victini',
    nameKo: '비크티니',
    code: '',
    type: ['psychic', 'fire'],
    url: '',
  },
  {
    name: 'Snivy',
    nameKo: '주리비얀',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Servine',
    nameKo: '샤비',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Serperior',
    nameKo: '샤로다',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Tepig',
    nameKo: '뚜꾸리',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Pignite',
    nameKo: '챠오꿀',
    code: '',
    type: ['fire', 'fighting'],
    url: '',
  },
  {
    name: 'Emboar',
    nameKo: '염무왕',
    code: '',
    type: ['fire', 'fighting'],
    url: '',
  },
  {
    name: 'Oshawott',
    nameKo: '수댕이',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Dewott',
    nameKo: '쌍검자비',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Samurott',
    nameKo: '대검귀',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Samurott-Hisui',
    nameKo: '히스이대검귀',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Patrat',
    nameKo: '보르쥐',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Watchog',
    nameKo: '보르그',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Lillipup',
    nameKo: '요테리',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Herdier',
    nameKo: '하데리어',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Stoutland',
    nameKo: '바랜드',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Purrloin',
    nameKo: '쌔비냥',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Liepard',
    nameKo: '레파르다스',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Pansage',
    nameKo: '야나프',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Simisage',
    nameKo: '야나키',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Pansear',
    nameKo: '바오프',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Simisear',
    nameKo: '바오키',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Panpour',
    nameKo: '앗차프',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Simipour',
    nameKo: '앗차키',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Munna',
    nameKo: '몽나',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Musharna',
    nameKo: '몽얌나',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Pidove',
    nameKo: '콩둘기',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Tranquill',
    nameKo: '유토브',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Unfezant-Male',
    nameKo: '켄호로우-수컷',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Unfezant-Female',
    nameKo: '켄호로우-암컷',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Blitzle',
    nameKo: '줄뮤마',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Zebstrika',
    nameKo: '제브라이카',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Roggenrola',
    nameKo: '단굴',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Boldore',
    nameKo: '암트르',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Gigalith',
    nameKo: '기가이어스',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Woobat',
    nameKo: '또르박쥐',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Swoobat',
    nameKo: '맘박쥐',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Drilbur',
    nameKo: '두더류',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Excadrill',
    nameKo: '몰드류',
    code: '',
    type: ['ground', 'steel'],
    url: '',
  },
  {
    name: 'Audino',
    nameKo: '다부니',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Audino-Mega',
    nameKo: '메가다부니',
    code: '',
    type: ['normal', 'fairy'],
    url: '',
  },
  {
    name: 'Timburr',
    nameKo: '으랏차',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Gurdurr',
    nameKo: '토쇠골',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Conkeldurr',
    nameKo: '노보청',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Tympole',
    nameKo: '동챙이',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Palpitoad',
    nameKo: '두까비',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Seismitoad',
    nameKo: '두빅굴',
    code: '',
    type: ['water', 'ground'],
    url: '',
  },
  {
    name: 'Throh',
    nameKo: '던지미',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Sawk',
    nameKo: '타격귀',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Sewaddle',
    nameKo: '두르보',
    code: '',
    type: ['bug', 'grass'],
    url: '',
  },
  {
    name: 'Swadloon',
    nameKo: '두르쿤',
    code: '',
    type: ['bug', 'grass'],
    url: '',
  },
  {
    name: 'Leavanny',
    nameKo: '모아머',
    code: '',
    type: ['bug', 'grass'],
    url: '',
  },
  {
    name: 'Venipede',
    nameKo: '마디네',
    code: '',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Whirlipede',
    nameKo: '휠구',
    code: '',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Scolipede',
    nameKo: '펜드라',
    code: '',
    type: ['bug', 'poison'],
    url: '',
  },
  {
    name: 'Cottonee',
    nameKo: '소미안',
    code: '',
    type: ['grass', 'fairy'],
    url: '',
  },
  {
    name: 'Whimsicott',
    nameKo: '엘풍',
    code: '',
    type: ['grass', 'fairy'],
    url: '',
  },
  {
    name: 'Petilil',
    nameKo: '치릴리',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Lilligant',
    nameKo: '드레디어',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Lilligant-Hisui',
    nameKo: '히스이드레디어',
    code: '',
    type: ['grass', 'fighting'],
    url: '',
  },
  {
    name: 'Basculin-RedStriped',
    nameKo: '배쓰나이-적색근',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Basculin-BlueStriped',
    nameKo: '배쓰나이-청색근',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Basculin-WhiteStriped',
    nameKo: '배쓰나이-백색근',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Sandile',
    nameKo: '깜눈크',
    code: '',
    type: ['ground', 'dark'],
    url: '',
  },
  {
    name: 'Krokorok',
    nameKo: '악비르',
    code: '',
    type: ['ground', 'dark'],
    url: '',
  },
  {
    name: 'Krookodile',
    nameKo: '악비아르',
    code: '',
    type: ['ground', 'dark'],
    url: '',
  },
  {
    name: 'Darumaka',
    nameKo: '달막화',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Darumaka-Galar',
    nameKo: '가라르달막화',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Darmanitan',
    nameKo: '불비달마',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Darmanitan-Galar',
    nameKo: '가라르불비달마',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Darmanitan-Zen',
    nameKo: '불비달마-달마모드',
    code: '',
    type: ['fire', 'psychic'],
    url: '',
  },
  {
    name: 'Darmanitan-Galar-Zen',
    nameKo: '가라르불비달마-달마모드',
    code: '',
    type: ['ice', 'fire'],
    url: '',
  },
  {
    name: 'Maractus',
    nameKo: '마라카치',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Dwebble',
    nameKo: '돌살이',
    code: '',
    type: ['bug', 'rock'],
    url: '',
  },
  {
    name: 'Crustle',
    nameKo: '암팰리스',
    code: '',
    type: ['bug', 'rock'],
    url: '',
  },
  {
    name: 'Scraggy',
    nameKo: '곤율랭',
    code: '',
    type: ['dark', 'fighting'],
    url: '',
  },
  {
    name: 'Scrafty',
    nameKo: '곤율거니',
    code: '',
    type: ['dark', 'fighting'],
    url: '',
  },
  {
    name: 'Siglyph',
    nameKo: '심보러',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Yamask',
    nameKo: '데스마스',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Yamask-Galar',
    nameKo: '가라르데스마스',
    code: '',
    type: ['ground', 'ghost'],
    url: '',
  },
  {
    name: 'Cofagrigus',
    nameKo: '데스니칸',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Tirtouga',
    nameKo: '프로토가',
    code: '',
    type: ['water', 'rock'],
    url: '',
  },
  {
    name: 'Carracosta',
    nameKo: '늑골라',
    code: '',
    type: ['water', 'rock'],
    url: '',
  },
  {
    name: 'Archen',
    nameKo: '아켄',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Archeops',
    nameKo: '아케오스',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Trubbish',
    nameKo: '깨봉이',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Garbodor',
    nameKo: '더스트나',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Zorua',
    nameKo: '조로아',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Zorua-Hisui',
    nameKo: '히스이조로아',
    code: '',
    type: ['normal', 'ghost'],
    url: '',
  },
  {
    name: 'Zoroark',
    nameKo: '조로아크',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Zoroark-Hisui',
    nameKo: '히스이조로아크',
    code: '',
    type: ['normal', 'ghost'],
    url: '',
  },
  {
    name: 'Minccino',
    nameKo: '치라미',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Chinccino',
    nameKo: '치라치노',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Gothita',
    nameKo: '고디탱',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Gothorita',
    nameKo: '고디보미',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Gothitelle',
    nameKo: '고디모아젤',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Solosis',
    nameKo: '유니란',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Duosion',
    nameKo: '듀란',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Reuniclus',
    nameKo: '란쿨루스',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Ducklett',
    nameKo: '꼬지보리',
    code: '',
    type: ['water', 'flying'],
    url: '',
  },
  {
    name: 'Swanna',
    nameKo: '스완나',
    code: '',
    type: ['water', 'flying'],
    url: '',
  },
  {
    name: 'Vanillite',
    nameKo: '바닐프티',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Vanillish',
    nameKo: '바닐리치',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Vanilluxe',
    nameKo: '배바닐라',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Deerling-Spring',
    nameKo: '사철록-봄',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Deerling-Summer',
    nameKo: '사철록-여름',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Deerling-Autumn',
    nameKo: '사철록-가을',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Deerling-Winter',
    nameKo: '사철록-겨울',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Sawsbuck-Spring',
    nameKo: '바라철록-봄',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Sawsbuck-Summer',
    nameKo: '바라철록-여름',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Sawsbuck-Autumn',
    nameKo: '바라철록-가을',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Sawsbuck-Winter',
    nameKo: '바라철록-겨울',
    code: '',
    type: ['normal', 'grass'],
    url: '',
  },
  {
    name: 'Emolga',
    nameKo: '에몽가',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: 'Karrablast',
    nameKo: '딱정곤',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Escavalier',
    nameKo: '슈바르고',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Foongus',
    nameKo: '깜놀버슬',
    code: '',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Amoongus',
    nameKo: '뽀록나',
    code: '',
    type: ['grass', 'poison'],
    url: '',
  },
  {
    name: 'Frillish-Male',
    nameKo: '탱그릴-수컷',
    code: '',
    type: ['water', 'ghost'],
    url: '',
  },
  {
    name: 'Frillish-Female',
    nameKo: '탱그릴-암컷',
    code: '',
    type: ['water', 'ghost'],
    url: '',
  },
  {
    name: 'Jellicent-Male',
    nameKo: '탱탱겔-수컷',
    code: '',
    type: ['water', 'ghost'],
    url: '',
  },
  {
    name: 'Jellicent-Female',
    nameKo: '탱탱겔-암컷',
    code: '',
    type: ['water', 'ghost'],
    url: '',
  },
  {
    name: 'Alomomola',
    nameKo: '맘복치',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Joltik',
    nameKo: '파쪼옥',
    code: '',
    type: ['bug', 'electric'],
    url: '',
  },
  {
    name: 'Galvantula',
    nameKo: '전툴라',
    code: '',
    type: ['bug', 'electric'],
    url: '',
  },
  {
    name: 'Ferroseed',
    nameKo: '철시드',
    code: '',
    type: ['grass', 'steel'],
    url: '',
  },
  {
    name: 'Ferrothorn',
    nameKo: '너트령',
    code: '',
    type: ['grass', 'steel'],
    url: '',
  },
  {
    name: 'Klink',
    nameKo: '기어르',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Klang',
    nameKo: '기기어르',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Klinklang',
    nameKo: '기기기어르',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Tynamo',
    nameKo: '저리어',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Elektrik',
    nameKo: '저리릴',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Elektross',
    nameKo: '저리더프',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Elgyem',
    nameKo: '리그레',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Beheeyem',
    nameKo: '벰크',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Litwick',
    nameKo: '불켜미',
    code: '',
    type: ['ghost', 'fire'],
    url: '',
  },
  {
    name: 'Lampent',
    nameKo: '램프라',
    code: '',
    type: ['ghost', 'fire'],
    url: '',
  },
  {
    name: 'Chandelure',
    nameKo: '샹델라',
    code: '',
    type: ['ghost', 'fire'],
    url: '',
  },
  {
    name: 'Axew',
    nameKo: '터검니',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Fraxure',
    nameKo: '액슨도',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Haxorus',
    nameKo: '액스라이즈',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Cubchoo',
    nameKo: '코고미',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Beartic',
    nameKo: '툰베어',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Cryogonal',
    nameKo: '프리지오',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Shelmet',
    nameKo: '쪼마리',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Accelgor',
    nameKo: '어지리더',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Stunfisk',
    nameKo: '메더',
    code: '',
    type: ['ground', 'electric'],
    url: '',
  },
  {
    name: 'Stunfisk-Galar',
    nameKo: '가라르메더',
    code: '',
    type: ['ground', 'steel'],
    url: '',
  },
  {
    name: 'Mienfoo',
    nameKo: '비조푸',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Mienshao',
    nameKo: '비조도',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Druddigon',
    nameKo: '크리만',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Golett',
    nameKo: '골비람',
    code: '',
    type: ['ground', 'ghost'],
    url: '',
  },
  {
    name: 'Golurk',
    nameKo: '골루그',
    code: '',
    type: ['ground', 'ghost'],
    url: '',
  },
  {
    name: 'Pawniard',
    nameKo: '자망칼',
    code: '',
    type: ['dark', 'steel'],
    url: '',
  },
  {
    name: 'Bisharp',
    nameKo: '절각참',
    code: '',
    type: ['dark', 'steel'],
    url: '',
  },
  {
    name: 'Bouffalant',
    nameKo: '버프론',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Rufflet',
    nameKo: '수리둥보',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Braviary',
    nameKo: '워글',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Braviary-Hisui',
    nameKo: '히스이워글',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Vullaby',
    nameKo: '벌차이',
    code: '',
    type: ['dark', 'flying'],
    url: '',
  },
  {
    name: 'Mandibuzz',
    nameKo: '버랜지나',
    code: '',
    type: ['dark', 'flying'],
    url: '',
  },
  {
    name: 'Heatmor',
    nameKo: '앤티골',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Durant',
    nameKo: '아이앤트',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Deino',
    nameKo: '모노두',
    code: '',
    type: ['dark', 'dragon'],
    url: '',
  },
  {
    name: 'Zweilous',
    nameKo: '디헤드',
    code: '',
    type: ['dark', 'dragon'],
    url: '',
  },
  {
    name: 'Hydreigon',
    nameKo: '삼삼드래',
    code: '',
    type: ['dark', 'dragon'],
    url: '',
  },
  {
    name: 'Larvesta',
    nameKo: '활화르바',
    code: '',
    type: ['bug', 'fire'],
    url: '',
  },
  {
    name: 'Volcarona',
    nameKo: '불카모스',
    code: '',
    type: ['bug', 'fire'],
    url: '',
  },
  {
    name: 'Kobalion',
    nameKo: '코바르온',
    code: '',
    type: ['steel', 'fighting'],
    url: '',
  },
  {
    name: 'Terrakion',
    nameKo: '테라키온',
    code: '',
    type: ['rock', 'fighting'],
    url: '',
  },
  {
    name: 'Virizion',
    nameKo: '비리디온',
    code: '',
    type: ['grass', 'fighting'],
    url: '',
  },
  {
    name: 'Tornadus-Incarnate',
    nameKo: '토네로스-화신',
    code: '',
    type: ['flying'],
    url: '',
  },
  {
    name: 'Tornadus-Therian',
    nameKo: '토네로스-영물',
    code: '',
    type: ['flying'],
    url: '',
  },
  {
    name: 'Thundurus-Incarnate',
    nameKo: '볼트로스-화신',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: 'Thundurus-Therian',
    nameKo: '볼트로스-영물',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: 'Reshiram',
    nameKo: '레시라무',
    code: '',
    type: ['dragon', 'fire'],
    url: '',
  },
  {
    name: 'Zekrom',
    nameKo: '제크로무',
    code: '',
    type: ['dragon', 'electric'],
    url: '',
  },
  {
    name: 'Landorus-Incarnate',
    nameKo: '랜드로스-화신',
    code: '',
    type: ['ground', 'flying'],
    url: '',
  },
  {
    name: 'Landorus-Therian',
    nameKo: '랜드로스-영물',
    code: '',
    type: ['ground', 'flying'],
    url: '',
  },
  {
    name: 'Kyurem',
    nameKo: '큐레무',
    code: '',
    type: ['dragon', 'ice'],
    url: '',
  },
  {
    name: 'Kyurem-White',
    nameKo: '화이트큐레무',
    code: '',
    type: ['dragon', 'ice'],
    url: '',
  },
  {
    name: 'Kyurem-Black',
    nameKo: '블랙큐레무',
    code: '',
    type: ['dragon', 'ice'],
    url: '',
  },
  {
    name: 'Keldeo',
    nameKo: '케르디오',
    code: '',
    type: ['water', 'fighting'],
    url: '',
  },
  {
    name: 'Keldeo-Resolute',
    nameKo: '케르디오-각오의모습',
    code: '',
    type: ['water', 'fighting'],
    url: '',
  },
  {
    name: 'Meloetta-Aria',
    nameKo: '메로엣타-보이스폼',
    code: '',
    type: ['normal', 'psychic'],
    url: '',
  },
  {
    name: 'Meloetta-Pirouette',
    nameKo: '메로엣타-스텝폼',
    code: '',
    type: ['normal', 'fighting'],
    url: '',
  },
  {
    name: 'Genesect',
    nameKo: '게노세크트',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Genesect-Douse',
    nameKo: '게노세크트-아쿠아',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Genesect-Shock',
    nameKo: '게노세크트-라이트닝',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Genesect-Burn',
    nameKo: '게노세크트-블레이즈',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Genesect-Chill',
    nameKo: '게노세크트-프리즈',
    code: '',
    type: ['bug', 'steel'],
    url: '',
  },
  {
    name: 'Chespin',
    nameKo: '도치마론',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Quilladin',
    nameKo: '도치보구',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Chesnaught',
    nameKo: '브리가론',
    code: '',
    type: ['grass', 'fighting'],
    url: '',
  },
  {
    name: 'Fennekin',
    nameKo: '푸호꼬',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Braixen',
    nameKo: '테르나',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Delphox',
    nameKo: '마폭시',
    code: '',
    type: ['fire', 'psychic'],
    url: '',
  },
  {
    name: 'Froakie',
    nameKo: '개구마르',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Frogadier',
    nameKo: '개굴반장',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Greninja',
    nameKo: '개굴닌자',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Greninja-Ash',
    nameKo: '지우개굴닌자',
    code: '',
    type: ['water', 'dark'],
    url: '',
  },
  {
    name: 'Bunnelby',
    nameKo: '파르빗',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Diggersby',
    nameKo: '파르토',
    code: '',
    type: ['normal', 'ground'],
    url: '',
  },
  {
    name: 'Fletchling',
    nameKo: '화살꼬빈',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Fletchinder',
    nameKo: '불화살빈',
    code: '',
    type: ['fire', 'flying'],
    url: '',
  },
  {
    name: 'Talonflame',
    nameKo: '파이어로',
    code: '',
    type: ['fire', 'flying'],
    url: '',
  },
  {
    name: 'Scatterbug',
    nameKo: '분이벌레',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Spewpa',
    nameKo: '분떠도리',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Vivillon-IcySnow',
    nameKo: '비비용-빙설의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Polar',
    nameKo: '비비용-설국의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Tundra',
    nameKo: '비비용-설원의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Continental',
    nameKo: '비비용-대륙의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Garden',
    nameKo: '비비용-정원의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Elegant',
    nameKo: '비비용-우아한모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Meadow',
    nameKo: '비비용-화원의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Modern',
    nameKo: '비비용-모던한모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Marine',
    nameKo: '비비용-마린의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Archipelago',
    nameKo: '비비용-군도의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-HighPlains',
    nameKo: '비비용-황야의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Sandstorm',
    nameKo: '비비용-사진의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-River',
    nameKo: '비비용-대하의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Monsoon',
    nameKo: '비비용-스콜의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Savanna',
    nameKo: '비비용-사바나의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Sun',
    nameKo: '비비용-태양의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Ocean',
    nameKo: '비비용-오션의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Jungle',
    nameKo: '비비용-정글의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Fancy',
    nameKo: '비비용-팬시한모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Vivillon-Pokeball',
    nameKo: '비비용-볼의모양',
    code: '',
    type: ['bug', 'flying'],
    url: '',
  },
  {
    name: 'Litleo',
    nameKo: '레오꼬',
    code: '',
    type: ['fire', 'normal'],
    url: '',
  },
  {
    name: 'Pyroar-Male',
    nameKo: '화염레오-수컷',
    code: '',
    type: ['fire', 'normal'],
    url: '',
  },
  {
    name: 'Pyroar-Female',
    nameKo: '화염레오-암컷',
    code: '',
    type: ['fire', 'normal'],
    url: '',
  },
  {
    name: 'Flabébé-Red',
    nameKo: '플라베베-빨간꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Flabébé-Yellow',
    nameKo: '플라베베-노란꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Flabébé-Orange',
    nameKo: '플라베베-오렌지색꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Flabébé-Blue',
    nameKo: '플라베베-파란꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Flabébé-White',
    nameKo: '플라베베-하얀꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Floette-Red',
    nameKo: '플라엣테-빨간꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Floette-Yellow',
    nameKo: '플라엣테-노란꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Floette-Orange',
    nameKo: '플라엣테-오렌지색꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Floette-Blue',
    nameKo: '플라엣테-파란꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Floette-White',
    nameKo: '플라엣테-하얀꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Floette-Eternal',
    nameKo: '플라엣테-영원의꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Florges-Red',
    nameKo: '플라제스-빨간꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Florges-Yellow',
    nameKo: '플라제스-노란꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Florges-Orange',
    nameKo: '플라제스-오렌지색꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Florges-Blue',
    nameKo: '플라제스-파란꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Florges-White',
    nameKo: '플라제스-하얀꽃',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Skiddo',
    nameKo: '메이클',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Gogoat',
    nameKo: '고고트',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Pancham',
    nameKo: '판짱',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Pangoro',
    nameKo: '부란다',
    code: '',
    type: ['fighting', 'dark'],
    url: '',
  },
  {
    name: 'Furfrou',
    nameKo: '트리미앙',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Heart',
    nameKo: '트리미앙-하트컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Star',
    nameKo: '트리미앙-스타컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Diamond',
    nameKo: '트리미앙-다이아몬드컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Debutante',
    nameKo: '트리미앙-레이디컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Matron',
    nameKo: '트리미앙-마담컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Dandy',
    nameKo: '트리미앙-젠틀컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-LaReine',
    nameKo: '트리미앙-퀸컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Kabuki',
    nameKo: '트리미앙-가부키컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Furfrou-Pharaoh',
    nameKo: '트리미앙-킹덤컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Espurr',
    nameKo: '냐스퍼',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Meowstic-Male',
    nameKo: '냐오닉스-수컷',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Meowstic-Female',
    nameKo: '냐오닉스-암컷',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Honedge',
    nameKo: '단칼빙',
    code: '',
    type: ['steel', 'ghost'],
    url: '',
  },
  {
    name: 'Doublade',
    nameKo: '쌍검킬',
    code: '',
    type: ['steel', 'ghost'],
    url: '',
  },
  {
    name: 'Aegislash-ShieldForme',
    nameKo: '킬가르도-실드폼',
    code: '',
    type: ['steel', 'ghost'],
    url: '',
  },
  {
    name: 'Aegislash-BladeForme',
    nameKo: '킬가르도-블레이드폼',
    code: '',
    type: ['steel', 'ghost'],
    url: '',
  },
  {
    name: 'Spritzee',
    nameKo: '슈쁘',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Aromatisse',
    nameKo: '프레프티르',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Swirlix',
    nameKo: '나룸퍼프',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Slurpuff',
    nameKo: '나루림',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Inkay',
    nameKo: '오케이징',
    code: '',
    type: ['dark', 'psychic'],
    url: '',
  },
  {
    name: 'Malamar',
    nameKo: '칼라마네로',
    code: '',
    type: ['dark', 'psychic'],
    url: '',
  },
  {
    name: 'Binacle',
    nameKo: '거북손손',
    code: '',
    type: ['rock', 'water'],
    url: '',
  },
  {
    name: 'Barbaracle',
    nameKo: '거북손데스',
    code: '',
    type: ['rock', 'water'],
    url: '',
  },
  {
    name: 'Skrelp',
    nameKo: '수레기',
    code: '',
    type: ['poison', 'water'],
    url: '',
  },
  {
    name: 'Dragalge',
    nameKo: '드래캄',
    code: '',
    type: ['poison', 'dragon'],
    url: '',
  },
  {
    name: 'Clauncher',
    nameKo: '완철포',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Clawitzer',
    nameKo: '블로스터',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Helioptile',
    nameKo: '목도리키텔',
    code: '',
    type: ['electric', 'normal'],
    url: '',
  },
  {
    name: 'Heliolisk',
    nameKo: '일레도리자드',
    code: '',
    type: ['electric', 'normal'],
    url: '',
  },
  {
    name: 'Tyrunt',
    nameKo: '티고라스',
    code: '',
    type: ['rock', 'dragon'],
    url: '',
  },
  {
    name: 'Tyrantrum',
    nameKo: '견고라스',
    code: '',
    type: ['rock', 'dragon'],
    url: '',
  },
  {
    name: 'Amaura',
    nameKo: '아마루스',
    code: '',
    type: ['rock', 'ice'],
    url: '',
  },
  {
    name: 'Aurorus',
    nameKo: '아마루르가',
    code: '',
    type: ['rock', 'ice'],
    url: '',
  },
  {
    name: 'Sylveon',
    nameKo: '님피아',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Hawlucha',
    nameKo: '루차불',
    code: '',
    type: ['fighting', 'flying'],
    url: '',
  },
  {
    name: 'Dedenne',
    nameKo: '데덴네',
    code: '',
    type: ['electric', 'fairy'],
    url: '',
  },
  {
    name: 'Carbink',
    nameKo: '멜리시',
    code: '',
    type: ['rock', 'fairy'],
    url: '',
  },
  {
    name: 'Goomy',
    nameKo: '미끄메라',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Sliggoo',
    nameKo: '미끄네일',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Sliggoo-Hisui',
    nameKo: '히스이미끄네일',
    code: '',
    type: ['steel', 'dragon'],
    url: '',
  },
  {
    name: 'Goodra',
    nameKo: '미끄래곤',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Goodra-Hisui',
    nameKo: '히스이미끄래곤',
    code: '',
    type: ['steel', 'dragon'],
    url: '',
  },
  {
    name: 'Klefki',
    nameKo: '클레피',
    code: '',
    type: ['steel', 'fairy'],
    url: '',
  },
  {
    name: 'Phantump',
    nameKo: '나목령',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Trevenant',
    nameKo: '대로트',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Pumpkaboo-Average',
    nameKo: '호바귀-보통',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Pumpkaboo-Small',
    nameKo: '호바귀-소',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Pumpkaboo-Large',
    nameKo: '호바귀-대',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Pumpkaboo-Super',
    nameKo: '호바귀-특대',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Gourgeist-Average',
    nameKo: '펌킨인-보통',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Gourgeist-Small',
    nameKo: '펌킨인-소',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Gourgeist-Large',
    nameKo: '펌킨인-대',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Gourgeist-Super',
    nameKo: '펌킨인-특대',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Bergmite',
    nameKo: '꽁어름',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Avalugg',
    nameKo: '크레베이스',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Avalugg-Hisui',
    nameKo: '히스이크레베이스',
    code: '',
    type: ['ice', 'rock'],
    url: '',
  },
  {
    name: 'Noibat',
    nameKo: '음뱃',
    code: '',
    type: ['flying', 'dragon'],
    url: '',
  },
  {
    name: 'Noivern',
    nameKo: '음번',
    code: '',
    type: ['flying', 'dragon'],
    url: '',
  },
  {
    name: 'Xerneas',
    nameKo: '제르네아스',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Xerneas-Neutral',
    nameKo: '제르네아스-릴렉스폼',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Yveltal',
    nameKo: '이벨타르',
    code: '',
    type: ['dark', 'flying'],
    url: '',
  },
  {
    name: 'Zygarde-10%',
    nameKo: '지가르데-10%폼',
    code: '',
    type: ['dragon', 'ground'],
    url: '',
  },
  {
    name: 'Zygarde-50%',
    nameKo: '지가르데-50%폼',
    code: '',
    type: ['dragon', 'ground'],
    url: '',
  },
  {
    name: 'Zygarde-Complete',
    nameKo: '지가르데-퍼펙트폼',
    code: '',
    type: ['dragon', 'ground'],
    url: '',
  },
  {
    name: 'Diancie',
    nameKo: '디안시',
    code: '',
    type: ['rock', 'fairy'],
    url: '',
  },
  {
    name: 'Diancie-Mega',
    nameKo: '메가디안시',
    code: '',
    type: ['rock', 'fairy'],
    url: '',
  },
  {
    name: 'Hoopa-Confined',
    nameKo: '굴레에빠진후파',
    code: '',
    type: ['psychic', 'ghost'],
    url: '',
  },
  {
    name: 'Hoopa-Unbound',
    nameKo: '굴레를벗어난후파',
    code: '',
    type: ['psychic', 'dark'],
    url: '',
  },
  {
    name: 'Volcanion',
    nameKo: '볼케니온',
    code: '',
    type: ['fire', 'water'],
    url: '',
  },
  {
    name: 'Rowlet',
    nameKo: '나몰빼미',
    code: '',
    type: ['grass', 'flying'],
    url: '',
  },
  {
    name: 'Dartrix',
    nameKo: '빼미스로우',
    code: '',
    type: ['grass', 'flying'],
    url: '',
  },
  {
    name: 'Decidueye',
    nameKo: '모크나이퍼',
    code: '',
    type: ['grass', 'ghost'],
    url: '',
  },
  {
    name: 'Decidueye-Hisui',
    nameKo: '히스이모크나이퍼',
    code: '',
    type: ['grass', 'fighting'],
    url: '',
  },
  {
    name: 'Litten',
    nameKo: '냐오불',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Torracat',
    nameKo: '냐오히트',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Incineroar',
    nameKo: '어흥염',
    code: '',
    type: ['fire', 'dark'],
    url: '',
  },
  {
    name: 'Popplio',
    nameKo: '누리공',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Brionne',
    nameKo: '키요공',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Primarina',
    nameKo: '누리레느',
    code: '',
    type: ['water', 'fairy'],
    url: '',
  },
  {
    name: 'Pikipek',
    nameKo: '콕코구리',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Trumbeak',
    nameKo: '크라파',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Toucannon',
    nameKo: '왕큰부리',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Yungoos',
    nameKo: '영구스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Gumshoos',
    nameKo: '형사구스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Grubbin',
    nameKo: '턱지충이',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Charjabug',
    nameKo: '전지충이',
    code: '',
    type: ['bug', 'electric'],
    url: '',
  },
  {
    name: 'Vikavolt',
    nameKo: '투구뿌논',
    code: '',
    type: ['bug', 'electric'],
    url: '',
  },
  {
    name: 'Crabrawler',
    nameKo: '오기지게',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Crabominable',
    nameKo: '모단단게',
    code: '',
    type: ['fighting', 'ice'],
    url: '',
  },
  {
    name: 'Oricorio-Baile',
    nameKo: '춤추새-이글이글스타일',
    code: '',
    type: ['fire', 'flying'],
    url: '',
  },
  {
    name: 'Oricorio-PomPom',
    nameKo: '춤추새-파칙파칙스타일',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: "Oricorio-Pa'u",
    nameKo: '춤추새- 훌라훌라스타일',
    code: '',
    type: ['psychic', 'flying'],
    url: '',
  },
  {
    name: 'Oricorio-Sensu',
    nameKo: '춤추새-하늘하늘스타일',
    code: '',
    type: ['ghost', 'flying'],
    url: '',
  },
  {
    name: 'Cutiefly',
    nameKo: '에블리',
    code: '',
    type: ['bug', 'fairy'],
    url: '',
  },
  {
    name: 'Ribombee',
    nameKo: '에리본',
    code: '',
    type: ['bug', 'fairy'],
    url: '',
  },
  {
    name: 'Rockruff',
    nameKo: '암멍이',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Lycanroc-Midday',
    nameKo: '루가루암-한낮의모습',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Lycanroc-Midnight',
    nameKo: '루가루암-한밤중의모습',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Lycanroc-Dusk',
    nameKo: '루가루암-황혼의모습',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Wishiwashi-Solo',
    nameKo: '약어리-단독의모습',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Wishiwashi-School',
    nameKo: '약어리-군집의모습',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Mareanie',
    nameKo: '시마사리',
    code: '',
    type: ['poison', 'water'],
    url: '',
  },
  {
    name: 'Toxapex',
    nameKo: '더시마사리',
    code: '',
    type: ['poison', 'water'],
    url: '',
  },
  {
    name: 'Mudbray',
    nameKo: '머드나기',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Mudsdale',
    nameKo: '만마드',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Dewpider',
    nameKo: '물거미',
    code: '',
    type: ['water', 'bug'],
    url: '',
  },
  {
    name: 'Araquanid',
    nameKo: '깨비물거미',
    code: '',
    type: ['water', 'bug'],
    url: '',
  },
  {
    name: 'Fomantis',
    nameKo: '짜랑랑',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Lurantis',
    nameKo: '라란티스',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Morelull',
    nameKo: '자마슈',
    code: '',
    type: ['grass', 'fairy'],
    url: '',
  },
  {
    name: 'Shiinotic',
    nameKo: '마셰이드',
    code: '',
    type: ['grass', 'fairy'],
    url: '',
  },
  {
    name: 'Salandit',
    nameKo: '야도뇽',
    code: '',
    type: ['poison', 'fire'],
    url: '',
  },
  {
    name: 'Salazzle',
    nameKo: '염뉴트',
    code: '',
    type: ['poison', 'fire'],
    url: '',
  },
  {
    name: 'Stufful',
    nameKo: '포곰곰',
    code: '',
    type: ['normal', 'fighting'],
    url: '',
  },
  {
    name: 'Bewear',
    nameKo: '이븐곰',
    code: '',
    type: ['normal', 'fighting'],
    url: '',
  },
  {
    name: 'Bounsweet',
    nameKo: '달콤아',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Steenee',
    nameKo: '달무리나',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Tsareena',
    nameKo: '달코퀸',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Comfey',
    nameKo: '큐아링',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Oranguru',
    nameKo: '하랑우탄',
    code: '',
    type: ['normal', 'psychic'],
    url: '',
  },
  {
    name: 'Passimian',
    nameKo: '내던숭이',
    code: '',
    type: ['normal', 'fighting'],
    url: '',
  },
  {
    name: 'Wimpod',
    nameKo: '꼬시레',
    code: '',
    type: ['bug', 'water'],
    url: '',
  },
  {
    name: 'Golisopod',
    nameKo: '갑주무사',
    code: '',
    type: ['bug', 'water'],
    url: '',
  },
  {
    name: 'Sandygast',
    nameKo: '모래꿍',
    code: '',
    type: ['ghost', 'ground'],
    url: '',
  },
  {
    name: 'Palossand',
    nameKo: '모래성이당',
    code: '',
    type: ['ghost', 'ground'],
    url: '',
  },
  {
    name: 'Pyukumuku',
    nameKo: '해무기',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Type: Null',
    nameKo: '타입:널',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Silvally',
    nameKo: '실버디',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Minior-Meteor',
    nameKo: '메테노-유성의모습',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Minior-RedCore',
    nameKo: '메테노-빨간색코어',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Minior-OrangeCore',
    nameKo: '메테노-주황색코어',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Minior-YellowCore',
    nameKo: '메테노-노란색코어',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Minior-GreenCore',
    nameKo: '메테노-초록색코어',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Minior-BlueCore',
    nameKo: '메테노-옥색코어',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Minior-IndigoCore',
    nameKo: '메테노-파란색코어',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Minior-VioletCore',
    nameKo: '메테노-보라색코어',
    code: '',
    type: ['rock', 'flying'],
    url: '',
  },
  {
    name: 'Komala',
    nameKo: '자말라',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Turtonator',
    nameKo: '폭거북스',
    code: '',
    type: ['fire', 'dragon'],
    url: '',
  },
  {
    name: 'Togedemaru',
    nameKo: '토게데마루',
    code: '',
    type: ['electric', 'steel'],
    url: '',
  },
  {
    name: 'Mimikyu',
    nameKo: '따라큐',
    code: '',
    type: ['ghost', 'fairy'],
    url: '',
  },
  {
    name: 'Bruxish',
    nameKo: '치갈기',
    code: '',
    type: ['water', 'psychic'],
    url: '',
  },
  {
    name: 'Drampa',
    nameKo: '할비롱',
    code: '',
    type: ['normal', 'dragon'],
    url: '',
  },
  {
    name: 'Dhelmise',
    nameKo: '타타륜',
    code: '',
    type: ['ghost', 'grass'],
    url: '',
  },
  {
    name: 'Jangmo-o',
    nameKo: '짜랑꼬',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Hakamo-o',
    nameKo: '짜랑고우',
    code: '',
    type: ['dragon', 'fighting'],
    url: '',
  },
  {
    name: 'Kommo-o',
    nameKo: '짜랑고우거',
    code: '',
    type: ['dragon', 'fighting'],
    url: '',
  },
  {
    name: 'Tapu Koko',
    nameKo: '카푸꼬꼬꼭',
    code: '',
    type: ['electric', 'fairy'],
    url: '',
  },
  {
    name: 'Tapu Lele',
    nameKo: '카푸나비나',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Tapu Bulu',
    nameKo: '카푸브루루',
    code: '',
    type: ['grass', 'fairy'],
    url: '',
  },
  {
    name: 'Tapu Fini',
    nameKo: '카푸느지느',
    code: '',
    type: ['water', 'fairy'],
    url: '',
  },
  {
    name: 'Cosmog',
    nameKo: '코스모그',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Cosmoem',
    nameKo: '코스모움',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Solgaleo',
    nameKo: '솔가레오',
    code: '',
    type: ['psychic', 'steel'],
    url: '',
  },
  {
    name: 'Lunala',
    nameKo: '루나아라',
    code: '',
    type: ['psychic', 'ghost'],
    url: '',
  },
  {
    name: 'Nihilego',
    nameKo: '텅비드',
    code: '',
    type: ['rock', 'poison'],
    url: '',
  },
  {
    name: 'Buzzwole',
    nameKo: '매시붕',
    code: '',
    type: ['bug', 'fighting'],
    url: '',
  },
  {
    name: 'Pheromosa',
    nameKo: '페로코체',
    code: '',
    type: ['bug', 'fighting'],
    url: '',
  },
  {
    name: 'Xurkitree',
    nameKo: '전수목',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Celesteela',
    nameKo: '철화구야',
    code: '',
    type: ['steel', 'flying'],
    url: '',
  },
  {
    name: 'Kartana',
    nameKo: '종이신도',
    code: '',
    type: ['grass', 'steel'],
    url: '',
  },
  {
    name: 'Guzzlord',
    nameKo: '악식킹',
    code: '',
    type: ['dark', 'dragon'],
    url: '',
  },
  {
    name: 'Necrozma',
    nameKo: '네크로즈마',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Necrozma-Dusk-Mane',
    nameKo: '네크로즈마-황혼의갈기',
    code: '',
    type: ['psychic', 'steel'],
    url: '',
  },
  {
    name: 'Necrozma-Dawn-Wings',
    nameKo: '네크로즈마-새벽의날개',
    code: '',
    type: ['psychic', 'ghost'],
    url: '',
  },
  {
    name: 'Necrozma-Ultra',
    nameKo: '울트라네크로즈마',
    code: '',
    type: ['psychic', 'dragon'],
    url: '',
  },
  {
    name: 'Magearna',
    nameKo: '마기아나',
    code: '',
    type: ['steel', 'fairy'],
    url: '',
  },
  {
    name: 'Magearna-Original',
    nameKo: '마기아나-500년전의색',
    code: '',
    type: ['steel', 'fairy'],
    url: '',
  },
  {
    name: 'Marshadow',
    nameKo: '마샤도',
    code: '',
    type: ['fighting', 'ghost'],
    url: '',
  },
  {
    name: 'Poipole',
    nameKo: '베베놈',
    code: '',
    type: ['poison'],
    url: '',
  },
  {
    name: 'Naganadel',
    nameKo: '아고용',
    code: '',
    type: ['poison', 'dragon'],
    url: '',
  },
  {
    name: 'Stakataka',
    nameKo: '차곡차곡',
    code: '',
    type: ['rock', 'steel'],
    url: '',
  },
  {
    name: 'Blacephalon',
    nameKo: '두파팡',
    code: '',
    type: ['fire', 'ghost'],
    url: '',
  },
  {
    name: 'Zeraora',
    nameKo: '제라오라',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Meltan',
    nameKo: '멜탄',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Melmetal',
    nameKo: '멜메탈',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Grookey',
    nameKo: '흥나숭',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Thwackey',
    nameKo: '채키몽',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Rillaboom',
    nameKo: '고릴타',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Scorbunny',
    nameKo: '염버니',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Raboot',
    nameKo: '래비풋',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Cinderace',
    nameKo: '에이스번',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Sobble',
    nameKo: '울머기',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Drizzile',
    nameKo: '누겔레온',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Inteleon',
    nameKo: '인텔리레온',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Skwovet',
    nameKo: '탐리스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Greedent',
    nameKo: '요씽리스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Rookidee',
    nameKo: '파라꼬',
    code: '',
    type: ['flying'],
    url: '',
  },
  {
    name: 'Corvisquire',
    nameKo: '파크로우',
    code: '',
    type: ['flying'],
    url: '',
  },
  {
    name: 'Corviknight',
    nameKo: '아머까오',
    code: '',
    type: ['flying', 'steel'],
    url: '',
  },
  {
    name: 'Blipbug',
    nameKo: '두루지벌레',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Dottler',
    nameKo: '레돔벌레',
    code: '',
    type: ['bug', 'psychic'],
    url: '',
  },
  {
    name: 'Orbeetle',
    nameKo: '이올브',
    code: '',
    type: ['bug', 'psychic'],
    url: '',
  },
  {
    name: 'Nickit',
    nameKo: '훔처우',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Thievul',
    nameKo: '폭슬라이',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Gossifleur',
    nameKo: '꼬모카',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Eldegoss',
    nameKo: '백솜모카',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Wooloo',
    nameKo: '우르',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Dubwool',
    nameKo: '배우르',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Chewtle',
    nameKo: '깨물부기',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Drednaw',
    nameKo: '갈가부기',
    code: '',
    type: ['water', 'rock'],
    url: '',
  },
  {
    name: 'Yamper',
    nameKo: '멍파치',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Boltund',
    nameKo: '펄스멍',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Rolycoly',
    nameKo: '탄동',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Carkol',
    nameKo: '탄차곤',
    code: '',
    type: ['rock', 'fire'],
    url: '',
  },
  {
    name: 'Coalossal',
    nameKo: '석탄산',
    code: '',
    type: ['rock', 'fire'],
    url: '',
  },
  {
    name: 'Applin',
    nameKo: '과사삭벌레',
    code: '',
    type: ['dragon', 'grass'],
    url: '',
  },
  {
    name: 'Flapple',
    nameKo: '애프룡',
    code: '',
    type: ['dragon', 'grass'],
    url: '',
  },
  {
    name: 'Appletun',
    nameKo: '단지래플',
    code: '',
    type: ['dragon', 'grass'],
    url: '',
  },
  {
    name: 'Silicobra',
    nameKo: '모래뱀',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Sandaconda',
    nameKo: '사다이사',
    code: '',
    type: ['ground'],
    url: '',
  },
  {
    name: 'Cramorant',
    nameKo: '윽우지',
    code: '',
    type: ['flying', 'water'],
    url: '',
  },
  {
    name: 'Cramorant-Gulping',
    nameKo: '윽우지-그대로삼킨모습',
    code: '',
    type: ['flying', 'water'],
    url: '',
  },
  {
    name: 'Cramorant-Gorging',
    nameKo: '윽우지-통째로삼킨모습',
    code: '',
    type: ['flying', 'water'],
    url: '',
  },
  {
    name: 'Arrokuda',
    nameKo: '찌로꼬치',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Barraskewda',
    nameKo: '꼬치조',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Toxel',
    nameKo: '일레즌',
    code: '',
    type: ['electric', 'poison'],
    url: '',
  },
  {
    name: 'Toxtricity-Amped',
    nameKo: '스트린더-하이한모습',
    code: '',
    type: ['electric', 'poison'],
    url: '',
  },
  {
    name: 'Toxtricity-LowKey',
    nameKo: '스트린더-로우한모습',
    code: '',
    type: ['electric', 'poison'],
    url: '',
  },
  {
    name: 'Sizzlipede',
    nameKo: '태우지네',
    code: '',
    type: ['fire', 'bug'],
    url: '',
  },
  {
    name: 'Centiskorch',
    nameKo: '다태우지네',
    code: '',
    type: ['fire', 'bug'],
    url: '',
  },
  {
    name: 'Clobbopus',
    nameKo: '때때무노',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Grapploct',
    nameKo: '케오퍼스',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Sinistea',
    nameKo: '데인차',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Polteageist',
    nameKo: '포트데스',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Hatenna',
    nameKo: '몸지브림',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Hattrem',
    nameKo: '손지브림',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Hatterene',
    nameKo: '브리무음',
    code: '',
    type: ['psychic', 'fairy'],
    url: '',
  },
  {
    name: 'Impidimp',
    nameKo: '메롱꿍',
    code: '',
    type: ['dark', 'fairy'],
    url: '',
  },
  {
    name: 'Morgrem',
    nameKo: '쏘겨모',
    code: '',
    type: ['dark', 'fairy'],
    url: '',
  },
  {
    name: 'Grimmsnarl',
    nameKo: '오롱털',
    code: '',
    type: ['dark', 'fairy'],
    url: '',
  },
  {
    name: 'Obstagoon',
    nameKo: '가로막구리',
    code: '',
    type: ['dark', 'normal'],
    url: '',
  },
  {
    name: 'Perrserker',
    nameKo: '나이킹',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Cursola',
    nameKo: '산호르곤',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: "Sirfetch'd",
    nameKo: '창파나이트',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Mr. Rime',
    nameKo: '마임꽁꽁',
    code: '',
    type: ['ice', 'psychic'],
    url: '',
  },
  {
    name: 'Runerigus',
    nameKo: '데스판',
    code: '',
    type: ['ground', 'ghost'],
    url: '',
  },
  {
    name: 'Milcery',
    nameKo: '마빌크',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Vanilla-Cream',
    nameKo: '마휘핑-밀키바닐라',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Ruby-Cream',
    nameKo: '마휘핑-밀키루비',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Matcha-Cream',
    nameKo: '마휘핑-밀키말차',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Mint-Cream',
    nameKo: '마휘핑-밀키민트',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Lemon-Cream',
    nameKo: '마휘핑-밀키레몬',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Salted-Cream',
    nameKo: '마휘핑-밀키솔트',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Ruby-Swirl',
    nameKo: '마휘핑-루비믹스',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Caramel-Swirl',
    nameKo: '마휘핑-캐러멜믹스',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Alcremie-Rainbow-Swirl',
    nameKo: '마휘핑-트리플믹스',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Falinks',
    nameKo: '대여르',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Pincurchin',
    nameKo: '찌르성게',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Snom',
    nameKo: '누니머기',
    code: '',
    type: ['ice', 'bug'],
    url: '',
  },
  {
    name: 'Frosmoth',
    nameKo: '모스노우',
    code: '',
    type: ['ice', 'bug'],
    url: '',
  },
  {
    name: 'Stonjourner',
    nameKo: '돌헨진',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Eiscue-IceFace',
    nameKo: '빙큐보-아이스페이스',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Eiscue-NoiceFace',
    nameKo: '빙큐보-나이스페이스',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Indeedee-Male',
    nameKo: '에써르-수컷',
    code: '',
    type: ['psychic', 'normal'],
    url: '',
  },
  {
    name: 'Indeedee-Female',
    nameKo: '에써르-암컷',
    code: '',
    type: ['psychic', 'normal'],
    url: '',
  },
  {
    name: 'Morpeko-FullBelly',
    nameKo: '모르페코-배부른모양',
    code: '',
    type: ['electric', 'dark'],
    url: '',
  },
  {
    name: 'Morpeko-Hangry',
    nameKo: '모르페코-배고픈모양',
    code: '',
    type: ['electric', 'dark'],
    url: '',
  },
  {
    name: 'Cufant',
    nameKo: '끼리동',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Copperajah',
    nameKo: '대왕끼리동',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Dracozolt',
    nameKo: '파치래곤',
    code: '',
    type: ['electric', 'dragon'],
    url: '',
  },
  {
    name: 'Arctozolt',
    nameKo: '파치르돈',
    code: '',
    type: ['electric', 'ice'],
    url: '',
  },
  {
    name: 'Dracovish',
    nameKo: '어래곤',
    code: '',
    type: ['water', 'dragon'],
    url: '',
  },
  {
    name: 'Arctovish',
    nameKo: '어치르돈',
    code: '',
    type: ['water', 'ice'],
    url: '',
  },
  {
    name: 'Duraludon',
    nameKo: '두랄루돈',
    code: '',
    type: ['steel', 'dragon'],
    url: '',
  },
  {
    name: 'Dreepy',
    nameKo: '드라꼰',
    code: '',
    type: ['dragon', 'ghost'],
    url: '',
  },
  {
    name: 'Drakloak',
    nameKo: '드래런치',
    code: '',
    type: ['dragon', 'ghost'],
    url: '',
  },
  {
    name: 'Dragapult',
    nameKo: '드래펄트',
    code: '',
    type: ['dragon', 'ghost'],
    url: '',
  },
  {
    name: 'Zacian-HeroOfManyBattles',
    nameKo: '자시안-역전의용사',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Zacian-Crowned',
    nameKo: '자시안-검왕',
    code: '',
    type: ['fairy', 'steel'],
    url: '',
  },
  {
    name: 'Zamazenta-HeroOfManyBattles',
    nameKo: '자마젠타-역전의용사',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Zamazenta-Crowned',
    nameKo: '자마젠타-방패왕',
    code: '',
    type: ['fighting', 'steel'],
    url: '',
  },
  {
    name: 'Eternatus',
    nameKo: '무한다이노',
    code: '',
    type: ['poison', 'dragon'],
    url: '',
  },
  {
    name: 'Kubfu',
    nameKo: '치고마',
    code: '',
    type: ['fighting'],
    url: '',
  },
  {
    name: 'Urshifu-SingleStrikeStyle',
    nameKo: '우라오스-일격의태세',
    code: '',
    type: ['fighting', 'dark'],
    url: '',
  },
  {
    name: 'Urshifu-RapidStrikeStyle',
    nameKo: '우라오스-연격의태세',
    code: '',
    type: ['fighting', 'water'],
    url: '',
  },
  {
    name: 'Zarude',
    nameKo: '자루도',
    code: '',
    type: ['dark', 'grass'],
    url: '',
  },
  {
    name: 'Zarude-Dada',
    nameKo: '자루도-아빠',
    code: '',
    type: ['dark', 'grass'],
    url: '',
  },
  {
    name: 'Regieleki',
    nameKo: '레지에레키',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Regidrago',
    nameKo: '레지드래고',
    code: '',
    type: ['dragon'],
    url: '',
  },
  {
    name: 'Glastrier',
    nameKo: '블리자포스',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Spectrier',
    nameKo: '레이스포스',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Calyrex',
    nameKo: '버드렉스',
    code: '',
    type: ['psychic', 'grass'],
    url: '',
  },
  {
    name: 'Calyrex-IceRider',
    nameKo: '버드렉스-백마탄모습',
    code: '',
    type: ['psychic', 'ice'],
    url: '',
  },
  {
    name: 'Calyrex-ShadowRider',
    nameKo: '버드렉스-흑마탄모습',
    code: '',
    type: ['psychic', 'ghost'],
    url: '',
  },
  {
    name: 'Wyrdeer',
    nameKo: '신비록',
    code: '',
    type: ['normal', 'psychic'],
    url: '',
  },
  {
    name: 'Kleavor',
    nameKo: '사마자르',
    code: '',
    type: ['bug', 'rock'],
    url: '',
  },
  {
    name: 'Ursaluna',
    nameKo: '다투곰',
    code: '',
    type: ['ground', 'normal'],
    url: '',
  },
  {
    name: 'Ursaluna-Bloodmoon',
    nameKo: '다투곰-붉은달',
    code: '',
    type: ['ground', 'normal'],
    url: '',
  },
  {
    name: 'Basculegion-Male',
    nameKo: '대쓰여너-수컷',
    code: '',
    type: ['water', 'ghost'],
    url: '',
  },
  {
    name: 'Basculegion-Female',
    nameKo: '대쓰여너-암컷',
    code: '',
    type: ['water', 'ghost'],
    url: '',
  },
  {
    name: 'Sneasler',
    nameKo: '포푸니크',
    code: '',
    type: ['fighting', 'poison'],
    url: '',
  },
  {
    name: 'Overqwil',
    nameKo: '장침바루',
    code: '',
    type: ['dark', 'poison'],
    url: '',
  },
  {
    name: 'Enamorus-Incarnate',
    nameKo: '러브로스-화신',
    code: '',
    type: ['fairy', 'flying'],
    url: '',
  },
  {
    name: 'Enamorus-Therian',
    nameKo: '러브로스-영물',
    code: '',
    type: ['fairy', 'flying'],
    url: '',
  },
  {
    name: 'Sprigatito',
    nameKo: '나오하',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Floragato',
    nameKo: '나로테',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Meowscarada',
    nameKo: '마스카나',
    code: '',
    type: ['grass', 'dark'],
    url: '',
  },
  {
    name: 'Fuecoco',
    nameKo: '뜨아거',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Crocalor',
    nameKo: '악뜨거',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Skeledirge',
    nameKo: '라우드본',
    code: '',
    type: ['fire', 'ghost'],
    url: '',
  },
  {
    name: 'Quaxly',
    nameKo: '꾸왁스',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Quaxwell',
    nameKo: '아꾸왁',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Quaquaval',
    nameKo: '웨이니발',
    code: '',
    type: ['water', 'fighting'],
    url: '',
  },
  {
    name: 'Lechonk',
    nameKo: '맛보돈',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Oinkologne-Male',
    nameKo: '퍼퓨돈-수컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Oinkologne-Female',
    nameKo: '퍼퓨돈-암컷',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Tarountula',
    nameKo: '타랜툴라',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Spidops',
    nameKo: '트래피더',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Nymble',
    nameKo: '콩알뚜기',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Lokix',
    nameKo: '엑스레그',
    code: '',
    type: ['bug', 'dark'],
    url: '',
  },
  {
    name: 'Pawmi',
    nameKo: '빠모',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Pawmo',
    nameKo: '빠모트',
    code: '',
    type: ['electric', 'fighting'],
    url: '',
  },
  {
    name: 'Pawmot',
    nameKo: '빠르모트',
    code: '',
    type: ['electric', 'fighting'],
    url: '',
  },
  {
    name: 'Tandemaus',
    nameKo: '두리쥐',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Maushold-Three',
    nameKo: '파밀리쥐-세식구',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Maushold-Four',
    nameKo: '파밀리쥐-네식구',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Fidough',
    nameKo: '쫀도기',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Dachsbun',
    nameKo: '바우첼',
    code: '',
    type: ['fairy'],
    url: '',
  },
  {
    name: 'Smoliv',
    nameKo: '미니브',
    code: '',
    type: ['grass', 'normal'],
    url: '',
  },
  {
    name: 'Dolliv',
    nameKo: '올리뇨',
    code: '',
    type: ['grass', 'normal'],
    url: '',
  },
  {
    name: 'Arboliva',
    nameKo: '올리르바',
    code: '',
    type: ['grass', 'normal'],
    url: '',
  },
  {
    name: 'Squawkabilly-GreenPlumage',
    nameKo: '시비꼬-그린페더',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Squawkabilly-BluePlumage',
    nameKo: '시비꼬-블루페더',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Squawkabilly-YellowPlumage',
    nameKo: '시비꼬-옐로페더',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Squawkabilly-WhitePlumage',
    nameKo: '시비꼬-화이트페더',
    code: '',
    type: ['normal', 'flying'],
    url: '',
  },
  {
    name: 'Nacli',
    nameKo: '베베솔트',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Naclstack',
    nameKo: '스태솔트',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Garganacl',
    nameKo: '콜로솔트',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Charcadet',
    nameKo: '카르본',
    code: '',
    type: ['fire'],
    url: '',
  },
  {
    name: 'Armarouge',
    nameKo: '카디나르마',
    code: '',
    type: ['fire', 'psychic'],
    url: '',
  },
  {
    name: 'Ceruledge',
    nameKo: '파라블레이즈',
    code: '',
    type: ['fire', 'ghost'],
    url: '',
  },
  {
    name: 'Tadbulb',
    nameKo: '빈나두',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Bellibolt',
    nameKo: '찌리배리',
    code: '',
    type: ['electric'],
    url: '',
  },
  {
    name: 'Wattrel',
    nameKo: '찌리비',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: 'Kilowattrel',
    nameKo: '찌리비크',
    code: '',
    type: ['electric', 'flying'],
    url: '',
  },
  {
    name: 'Maschiff',
    nameKo: '오라티프',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Mabosstiff',
    nameKo: '마피티프',
    code: '',
    type: ['dark'],
    url: '',
  },
  {
    name: 'Shroodle',
    nameKo: '땃쭈르',
    code: '',
    type: ['poison', 'normal'],
    url: '',
  },
  {
    name: 'Grafaiai',
    nameKo: '태깅구르',
    code: '',
    type: ['poison', 'normal'],
    url: '',
  },
  {
    name: 'Bramblin',
    nameKo: '그푸리',
    code: '',
    type: ['grass', 'ghost'],
    url: '',
  },
  {
    name: 'Brambleghast',
    nameKo: '공푸리',
    code: '',
    type: ['grass', 'ghost'],
    url: '',
  },
  {
    name: 'Toedscool',
    nameKo: '들눈해',
    code: '',
    type: ['ground', 'grass'],
    url: '',
  },
  {
    name: 'Toedscruel',
    nameKo: '육파리',
    code: '',
    type: ['ground', 'grass'],
    url: '',
  },
  {
    name: 'Klawf',
    nameKo: '절벼게',
    code: '',
    type: ['rock'],
    url: '',
  },
  {
    name: 'Capsakid',
    nameKo: '캡싸이',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Scovillain',
    nameKo: '스코빌런',
    code: '',
    type: ['grass', 'fire'],
    url: '',
  },
  {
    name: 'Rellor',
    nameKo: '구르데',
    code: '',
    type: ['bug'],
    url: '',
  },
  {
    name: 'Rabsca',
    nameKo: '베라카스',
    code: '',
    type: ['bug', 'psychic'],
    url: '',
  },
  {
    name: 'Flittle',
    nameKo: '하느라기',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Espathra',
    nameKo: '클레스퍼트라',
    code: '',
    type: ['psychic'],
    url: '',
  },
  {
    name: 'Tinkatink',
    nameKo: '어리짱',
    code: '',
    type: ['fairy', 'steel'],
    url: '',
  },
  {
    name: 'Tinkatuff',
    nameKo: '벼리짱',
    code: '',
    type: ['fairy', 'steel'],
    url: '',
  },
  {
    name: 'Tinkaton',
    nameKo: '두드리짱',
    code: '',
    type: ['fairy', 'steel'],
    url: '',
  },
  {
    name: 'Wiglett',
    nameKo: '바다그다',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Wugtrio',
    nameKo: '바닥트리오',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Bombirdier',
    nameKo: '떨구새',
    code: '',
    type: ['flying', 'dark'],
    url: '',
  },
  {
    name: 'Finizen',
    nameKo: '맨돌핀',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Palafin-Zero',
    nameKo: '돌핀맨-나이브폼',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Palafin-Hero',
    nameKo: '돌핀맨-마이티폼',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Varoom',
    nameKo: '부르롱',
    code: '',
    type: ['steel', 'poison'],
    url: '',
  },
  {
    name: 'Revavroom',
    nameKo: '부르르룸',
    code: '',
    type: ['steel', 'poison'],
    url: '',
  },
  {
    name: 'Cyclizar',
    nameKo: '모토마',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Orthworm',
    nameKo: '꿈트렁',
    code: '',
    type: ['steel'],
    url: '',
  },
  {
    name: 'Glimmet',
    nameKo: '초롱순',
    code: '',
    type: ['rock', 'poison'],
    url: '',
  },
  {
    name: 'Glimmora',
    nameKo: '킬라플로르',
    code: '',
    type: ['rock', 'poison'],
    url: '',
  },
  {
    name: 'Greavard',
    nameKo: '망망이',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Houndstone',
    nameKo: '묘두기',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Flamigo',
    nameKo: '꼬이밍고',
    code: '',
    type: ['flying', 'fighting'],
    url: '',
  },
  {
    name: 'Cetoddle',
    nameKo: '터벅고래',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Cetitan',
    nameKo: '우락고래',
    code: '',
    type: ['ice'],
    url: '',
  },
  {
    name: 'Veluza',
    nameKo: '가비루사',
    code: '',
    type: ['water', 'psychic'],
    url: '',
  },
  {
    name: 'Dondozo',
    nameKo: '어써러셔',
    code: '',
    type: ['water'],
    url: '',
  },
  {
    name: 'Tatsugiri-Curly',
    nameKo: '싸리용-젖힌모습',
    code: '',
    type: ['dragon', 'water'],
    url: '',
  },
  {
    name: 'Tatsugiri-Droopy',
    nameKo: '싸리용-늘어진모습',
    code: '',
    type: ['dragon', 'water'],
    url: '',
  },
  {
    name: 'Tatsugiri-Stretchy',
    nameKo: '싸리용-뻗은모습',
    code: '',
    type: ['dragon', 'water'],
    url: '',
  },
  {
    name: 'Annihilape',
    nameKo: '저승갓숭',
    code: '',
    type: ['fighting', 'ghost'],
    url: '',
  },
  {
    name: 'Clodsire',
    nameKo: '토오',
    code: '',
    type: ['poison', 'ground'],
    url: '',
  },
  {
    name: 'Farigiraf',
    nameKo: '키키링',
    code: '',
    type: ['normal', 'psychic'],
    url: '',
  },
  {
    name: 'Dudunsparce',
    nameKo: '노고고치',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Kingambit',
    nameKo: '대도각참',
    code: '',
    type: ['dark', 'steel'],
    url: '',
  },
  {
    name: 'Great Tusk',
    nameKo: '위대한엄니',
    code: '',
    type: ['ground', 'fighting'],
    url: '',
  },
  {
    name: 'Scream Tail',
    nameKo: '우렁찬꼬리',
    code: '',
    type: ['fairy', 'psychic'],
    url: '',
  },
  {
    name: 'Brute Bonnet',
    nameKo: '사나운버섯',
    code: '',
    type: ['grass', 'dark'],
    url: '',
  },
  {
    name: 'Flutter Mane',
    nameKo: '날개치는머리',
    code: '',
    type: ['ghost', 'fairy'],
    url: '',
  },
  {
    name: 'Slither Wing',
    nameKo: '땅을기는날개',
    code: '',
    type: ['bug', 'fighting'],
    url: '',
  },
  {
    name: 'Sandy Shocks',
    nameKo: '모래털가죽',
    code: '',
    type: ['electric', 'ground'],
    url: '',
  },
  {
    name: 'Iron Treads',
    nameKo: '무쇠바퀴',
    code: '',
    type: ['ground', 'steel'],
    url: '',
  },
  {
    name: 'Iron Bundle',
    nameKo: '무쇠보따리',
    code: '',
    type: ['ice', 'water'],
    url: '',
  },
  {
    name: 'Iron Hands',
    nameKo: '무쇠손',
    code: '',
    type: ['fighting', 'electric'],
    url: '',
  },
  {
    name: 'Iron Jugulis',
    nameKo: '무쇠머리',
    code: '',
    type: ['dark', 'flying'],
    url: '',
  },
  {
    name: 'Iron Moth',
    nameKo: '무쇠독나방',
    code: '',
    type: ['fire', 'poison'],
    url: '',
  },
  {
    name: 'Iron Thorns',
    nameKo: '무쇠가시',
    code: '',
    type: ['rock', 'electric'],
    url: '',
  },
  {
    name: 'Frigibax',
    nameKo: '드니차',
    code: '',
    type: ['dragon', 'ice'],
    url: '',
  },
  {
    name: 'Arctibax',
    nameKo: '드니꽁',
    code: '',
    type: ['dragon', 'ice'],
    url: '',
  },
  {
    name: 'Baxcalibur',
    nameKo: '드닐레이브',
    code: '',
    type: ['dragon', 'ice'],
    url: '',
  },
  {
    name: 'Gimmighoul-Chest',
    nameKo: '모으령-상자폼',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Gimmighoul-Roaming',
    nameKo: '모으령-도보폼',
    code: '',
    type: ['ghost'],
    url: '',
  },
  {
    name: 'Gholdengo',
    nameKo: '타부자고',
    code: '',
    type: ['steel', 'ghost'],
    url: '',
  },
  {
    name: 'Wo-Chien',
    nameKo: '총지엔',
    code: '',
    type: ['dark', 'grass'],
    url: '',
  },
  {
    name: 'Chien-Pao',
    nameKo: '파오젠',
    code: '',
    type: ['dark', 'ice'],
    url: '',
  },
  {
    name: 'Ting-Lu',
    nameKo: '딩루',
    code: '',
    type: ['dark', 'ground'],
    url: '',
  },
  {
    name: 'Chi-Yu',
    nameKo: '위유이',
    code: '',
    type: ['dark', 'fire'],
    url: '',
  },
  {
    name: 'Roaring Moon',
    nameKo: '고동치는달',
    code: '',
    type: ['dragon', 'dark'],
    url: '',
  },
  {
    name: 'Iron Valiant',
    nameKo: '무쇠무인',
    code: '',
    type: ['fairy', 'fighting'],
    url: '',
  },
  {
    name: 'Koraidon-ApexBuild',
    nameKo: '코라이돈-완전형태',
    code: '',
    type: ['fighting', 'dragon'],
    url: '',
  },
  {
    name: 'Koraidon-SprintingBuild',
    nameKo: '코라이돈-제한형태',
    code: '',
    type: ['fighting', 'dragon'],
    url: '',
  },
  {
    name: 'Miraidon-UltimateMode',
    nameKo: '미라이돈-컴플리트모드',
    code: '',
    type: ['electric', 'dragon'],
    url: '',
  },
  {
    name: 'Miraidon-DriveMode',
    nameKo: '미라이돈-리미티드모드',
    code: '',
    type: ['electric', 'dragon'],
    url: '',
  },
  {
    name: 'Walking Wake',
    nameKo: '굽이치는물결',
    code: '',
    type: ['water', 'dragon'],
    url: '',
  },
  {
    name: 'Iron Leaves',
    nameKo: '무쇠잎새',
    code: '',
    type: ['grass', 'psychic'],
    url: '',
  },
  {
    name: 'Dipplin',
    nameKo: '과미르',
    code: '',
    type: ['grass', 'dragon'],
    url: '',
  },
  {
    name: 'Poltchageist',
    nameKo: '차데스',
    code: '',
    type: ['grass', 'ghost'],
    url: '',
  },
  {
    name: 'Sinistcha',
    nameKo: '그우린차',
    code: '',
    type: ['grass', 'ghost'],
    url: '',
  },
  {
    name: 'Okidogi',
    nameKo: '조타구',
    code: '',
    type: ['poison', 'fighting'],
    url: '',
  },
  {
    name: 'Munkidori',
    nameKo: '이야후',
    code: '',
    type: ['poison', 'psychic'],
    url: '',
  },
  {
    name: 'Fezandipiti',
    nameKo: '기로치',
    code: '',
    type: ['poison', 'fairy'],
    url: '',
  },
  {
    name: 'Ogerpon-Teal',
    nameKo: '오거폰-벽록의가면',
    code: '',
    type: ['grass'],
    url: '',
  },
  {
    name: 'Ogerpon-Wellspring',
    nameKo: '오거폰-우물의가면',
    code: '',
    type: ['grass', 'water'],
    url: '',
  },
  {
    name: 'Ogerpon-Hearthflame',
    nameKo: '오거폰-화덕의가면',
    code: '',
    type: ['grass', 'fire'],
    url: '',
  },
  {
    name: 'Ogerpon-Cornerstone',
    nameKo: '오거폰-주춧돌의가면',
    code: '',
    type: ['grass', 'rock'],
    url: '',
  },
  {
    name: 'Archaludon',
    nameKo: '브리두라스',
    code: '',
    type: ['steel', 'dragon'],
    url: '',
  },
  {
    name: 'Hydrapple',
    nameKo: '과미드라',
    code: '',
    type: ['grass', 'dragon'],
    url: '',
  },
  {
    name: 'Gouging Fire',
    nameKo: '꿰뚫는화염',
    code: '',
    type: ['fire', 'dragon'],
    url: '',
  },
  {
    name: 'Raging Bolt',
    nameKo: '날뛰는우레',
    code: '',
    type: ['electric', 'dragon'],
    url: '',
  },
  {
    name: 'Iron Boulder',
    nameKo: '무쇠암석',
    code: '',
    type: ['rock', 'psychic'],
    url: '',
  },
  {
    name: 'Iron Crown',
    nameKo: '무쇠감투',
    code: '',
    type: ['steel', 'psychic'],
    url: '',
  },
  {
    name: 'Terapagos',
    nameKo: '테라파고스',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Terapagos-Terastal',
    nameKo: '테라파고스-테라스탈폼',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Terapagos-Stellar',
    nameKo: '테라파고스-스텔라폼',
    code: '',
    type: ['normal'],
    url: '',
  },
  {
    name: 'Pecharunt',
    nameKo: '복숭악동',
    code: '',
    type: ['poison', 'ghost'],
    url: '',
  },
];

for (let i = 0; i < db.length; i++) {
  db[i].url = categoryData[i];
  db[i].code = imageContext.keys()[i].slice(6, 10);
}

export { db, categoryData };
